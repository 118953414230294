export const inputs = {
  destiny: [
    {
      type: 'city',
      name: 'name',
      placeholder: 'Escolha uma cidade...',
      label: 'Nome',
      isClearable: true,
      required: true,
      sm: 4,
      lg: 4,
      xl: 4,
    },
    {
      type: 'date',
      name: 'dateStart',
      label: 'Data inicial',
      sm: 4,
      lg: 4,
      xl: 4,
    },
    {
      type: 'date',
      name: 'dateEnd',
      label: 'Data final',
      sm: 4,
      lg: 4,
      xl: 4,
    },
    {
      type: 'inputFile',
      name: 'files',
      label: 'Anexos',
      max: 5,
      sm: 12,
      lg: 12,
    },
  ],
  flight: [
    {
      type: 'text',
      name: 'descriptionPayment',
      label: 'Forma de pagamento',
      sm: 12,
      lg: 2,
    },
    {
      type: 'number',
      name: 'value',
      label: 'Valor',
      min: 0,
      sm: 12,
      lg: 2,
    },
    {
      type: 'number',
      name: 'passengers',
      label: 'N. de adultos',
      required: true,
      min: 0,
      sm: 12,
      lg: 2,
    },
    {
      type: 'number',
      name: 'numberOfChildren',
      label: 'N. de crianças',
      min: 0,
      sm: 12,
      lg: 2,
    },
    {
      type: 'number',
      name: 'numberOfBabies',
      label: 'N. de bebês',
      min: 0,
      sm: 12,
      lg: 2,
    },
    {
      type: 'select',
      name: 'baggage',
      label: 'Bagagem',
      required: true,
      options: [
        { label: 'Sim', value: true },
        { label: 'Não', value: false },
      ],
      sm: 12,
      lg: 2,
    },
    {
      type: 'select',
      name: 'type',
      label: 'Tipo',
      required: true,
      options: [
        { label: 'Ida', value: 'Ida' },
        { label: 'Ida e volta', value: 'Ida e Volta' },
      ],
      sm: 12,
      lg: 2,
    },
    {
      type: 'textarea',
      name: 'importantInformation',
      label: 'Informações importantes',
      sm: 12,
    },
    {
      type: 'inputFile',
      name: 'files',
      label: 'Anexos',
      max: 5,
      sm: 12,
      lg: 12,
    },
  ],
  hotel: [
    {
      type: 'text',
      name: 'name',
      label: 'Nome',
      required: true,
      sm: 6,
      lg: 6,
      xl: 6,
    },
    {
      type: 'text',
      name: 'fullAddress',
      label: 'Endereço',
      required: true,
      sm: 3,
      lg: 3,
      xl: 3,
    },
    {
      type: 'text',
      name: 'locationName',
      label: 'Cidade',
      required: true,
      sm: 3,
      lg: 3,
      xl: 3,
    },
    {
      type: 'text',
      name: 'roomType',
      label: 'Tipo do quarto',
      sm: 2,
      lg: 2,
      xl: 2,
    },
    {
      type: 'text',
      name: 'diet',
      label: 'Regime alimentar',
      sm: 2,
      lg: 2,
      xl: 2,
    },
    {
      type: 'date',
      name: 'dateCheckIn',
      label: 'Checkin',
      sm: 2,
      lg: 2,
      xl: 2,
    },
    {
      type: 'time',
      name: 'timeCheckIn',
      label: 'Horário do checkin',
      sm: 2,
      lg: 2,
      xl: 2,
    },
    {
      type: 'date',
      name: 'dateCheckOut',
      label: 'Checkout',
      sm: 2,
      lg: 2,
      xl: 2,
    },
    {
      type: 'time',
      name: 'timeCheckOut',
      label: 'Horário do checkout',
      sm: 2,
      lg: 2,
      xl: 2,
    },
    {
      type: 'number',
      min: 0,
      name: 'value',
      label: 'Valor',
      sm: 6,
      lg: 6,
      xl: 6,
    },
    {
      type: 'text',
      name: 'descriptionPayment',
      label: 'Forma de pagamento',
      sm: 6,
      lg: 6,
      xl: 6,
    },
    {
      type: 'textarea',
      name: 'importantInformation',
      label: 'Informações importantes',
      sm: 12,
      lg: 12,
      xl: 12,
    },
    {
      type: 'inputFile',
      name: 'files',
      label: 'Anexos',
      max: 5,
      sm: 12,
      lg: 12,
    },
  ],
  activity: [
    {
      type: 'text',
      name: 'startAddress',
      label: 'Local',
      required: true,
      sm: 12,
      lg: 5,
      xl: 5,
    },
    {
      type: 'date',
      name: 'date',
      label: 'Data',
      sm: 2,
      lg: 2,
      xl: 2,
    },
    {
      type: 'time',
      name: 'start',
      label: 'Horário',
      sm: 2,
      lg: 2,
      xl: 2,
    },
    {
      type: 'number',
      min: 0,
      name: 'value',
      label: 'Valor',
      sm: 3,
      lg: 1,
      xl: 1,
    },
    {
      type: 'text',
      name: 'descriptionPayment',
      label: 'Forma de pagamento',
      sm: 3,
      lg: 2,
      xl: 2,
    },
    {
      type: 'textarea',
      name: 'desc',
      label: 'Descrição',
      sm: 12,
      lg: 12,
      xl: 12,
    },
    {
      type: 'inputFile',
      name: 'files',
      label: 'Anexos',
      max: 5,
      sm: 12,
      lg: 12,
    },
  ],
  insurance: [
    {
      type: 'text',
      name: 'typeCoverage',
      label: 'Tipo de cobertura',
      required: true,
      sm: 4,
      lg: 4,
      xl: 5,
    },
    {
      type: 'date',
      name: 'timeStart',
      label: 'Início',
      sm: 4,
      lg: 4,
      xl: 2,
    },
    {
      type: 'date',
      name: 'timeEnd',
      label: 'Fim',
      sm: 4,
      lg: 4,
      xl: 2,
    },
    {
      type: 'number',
      min: 0,
      name: 'value',
      label: 'Valor',
      sm: 6,
      lg: 6,
      xl: 1,
    },
    {
      type: 'text',
      name: 'descriptionPayment',
      label: 'Forma de pagamento',
      sm: 6,
      lg: 6,
      xl: 2,
    },
    {
      type: 'textarea',
      name: 'description',
      label: 'Descrição da cobertura do seguro',
      sm: 12,
      lg: 12,
      xl: 12,
    },
    {
      type: 'inputFile',
      name: 'files',
      label: 'Anexos',
      max: 5,
      sm: 12,
      lg: 12,
    },
  ],
  nautical: [
    {
      type: 'text',
      name: 'cia',
      label: 'Cia',
      required: true,
      sm: 6,
      lg: 6,
      xl: 1,
    },
    {
      type: 'select',
      name: 'type',
      maxLength: 100,
      label: 'Tipo do náutico',
      options: [
        { label: 'Cruzeiro', value: 'Cruzeiro' },
        { label: 'Iate', value: 'Iate' },
        { label: 'Bote', value: 'Bote' },
        { label: 'Escuna', value: 'Escuna' },
        { label: 'Jangada', value: 'Jangada' },
        { label: 'Veleiro', value: 'Veleiro' },
        { label: 'Outros', value: 'Outros' },
      ],
      sm: 6,
      md: 6,
      lg: 6,
      xl: 2,
    },
    {
      type: 'text',
      name: 'cabinType',
      label: 'Tipo da cabine',
      sm: 6,
      lg: 6,
      xl: 1,
    },
    {
      type: 'number',
      min: 0,
      name: 'value',
      label: 'Valor',
      sm: 6,
      lg: 6,
      xl: 2,
    },
    {
      type: 'text',
      name: 'descriptionPayment',
      label: 'Forma de pagamento',
      sm: 6,
      lg: 6,
      xl: 2,
    },
    {
      type: 'text',
      name: 'seaportOrigin',
      label: 'Porto de origem',
      sm: 6,
      lg: 6,
      xl: 2,
    },
    {
      type: 'text',
      name: 'origin',
      label: 'Origem',
      sm: 6,
      lg: 6,
      xl: 2,
    },
    {
      type: 'date',
      name: 'departure',
      label: 'Partida',
      sm: 6,
      lg: 6,
      xl: 2,
    },
    {
      type: 'time',
      name: 'start',
      label: 'Horário de partida',
      sm: 6,
      lg: 6,
      xl: 2,
    },
    {
      type: 'text',
      name: 'seaportDestiny',
      label: 'Porto de destino',
      sm: 6,
      lg: 6,
      xl: 2,
    },
    {
      type: 'text',
      name: 'destiny',
      label: 'Destino',
      sm: 6,
      lg: 6,
      xl: 2,
    },
    {
      type: 'date',
      name: 'arrival',
      label: 'Chegada',
      sm: 6,
      lg: 6,
      xl: 2,
    },
    {
      type: 'time',
      name: 'end',
      label: 'Horário de chegada',
      sm: 6,
      lg: 6,
      xl: 2,
    },
    {
      type: 'textarea',
      name: 'importantInformation',
      label: 'Informações importantes',
      sm: 12,
      lg: 12,
      xl: 12,
    },
    {
      type: 'inputFile',
      name: 'files',
      label: 'Anexos',
      max: 5,
      sm: 12,
      lg: 12,
    },
  ],
  otherService: [
    {
      type: 'text',
      name: 'title',
      label: 'Titulo',
      required: true,
      sm: 4,
      lg: 4,
      xl: 4,
    },
    {
      type: 'text',
      name: 'startAddress',
      label: 'Endereço',
      sm: 4,
      lg: 4,
      xl: 4,
    },
    {
      type: 'city',
      name: 'city',
      label: 'Cidade',
      sm: 4,
      lg: 4,
      xl: 4,
    },
    {
      type: 'date',
      name: 'dateCheckIn',
      label: 'Data de início',
      sm: 3,
      lg: 3,
      xl: 2,
    },
    {
      type: 'time',
      name: 'timeCheckIn',
      label: 'Horário do início',
      sm: 3,
      lg: 3,
      xl: 2,
    },
    {
      type: 'date',
      name: 'dateCheckOut',
      label: 'Data do fim',
      sm: 3,
      lg: 3,
      xl: 2,
    },
    {
      type: 'time',
      name: 'timeCheckOut',
      label: 'Horário do fim',
      sm: 3,
      lg: 3,
      xl: 2,
    },
    {
      type: 'number',
      min: 0,
      name: 'value',
      label: 'Valor',
      sm: 6,
      lg: 6,
      xl: 2,
    },
    {
      type: 'text',
      name: 'descriptionPayment',
      label: 'Forma de pagamento',
      sm: 6,
      lg: 6,
      xl: 2,
    },
    {
      type: 'html',
      name: 'desc',
      label: 'Descrição',
      sm: 12,
      lg: 12,
      xl: 12,
    },
    {
      type: 'inputFile',
      name: 'files',
      label: 'Anexos',
      max: 5,
      sm: 12,
      lg: 12,
    },
  ],
  ticket: [
    {
      type: 'text',
      name: 'title',
      label: 'Título',
      required: true,
      sm: 4,
      md: 4,
      lg: 4,
      xl: 12,
    },
    {
      type: 'number',
      min: 0,
      name: 'value',
      label: 'Valor',
      sm: 4,
      lg: 4,
      xl: 2,
    },
    {
      type: 'text',
      name: 'descriptionPayment',
      label: 'Forma de pagamento',
      sm: 4,
      lg: 4,
      xl: 2,
    },
    {
      type: 'date',
      name: 'dateStart',
      label: 'Data do Início',
      sm: 12,
      md: 6,
      lg: 4,
      xl: 2,
    },
    {
      type: 'time',
      name: 'timeStart',
      label: 'Hora do Início',
      sm: 12,
      md: 6,
      lg: 4,
      xl: 2,
    },
    {
      type: 'date',
      name: 'dateEnd',
      label: 'Data do Fim',
      sm: 12,
      md: 6,
      lg: 4,
      xl: 2,
    },
    {
      type: 'time',
      name: 'timeEnd',
      label: 'Hora do Fim',
      sm: 12,
      md: 6,
      lg: 4,
      xl: 2,
    },
    {
      type: 'textarea',
      name: 'desc',
      label: 'Descrição',
      sm: 12,
      md: 12,
      lg: 12,
      xl: 12,
    },
    {
      type: 'inputFile',
      name: 'files',
      label: 'Anexos',
      max: 5,
      sm: 12,
      lg: 12,
    },
  ],
  train: [
    {
      type: 'text',
      name: 'cia',
      label: 'Cia',
      required: true,
      sm: 6,
      lg: 6,
      xl: 1,
    },
    {
      type: 'number',
      min: 0,
      name: 'value',
      label: 'Valor',
      sm: 6,
      lg: 6,
      xl: 1,
    },
    {
      type: 'text',
      name: 'stationOrigin',
      label: 'Estação de origem',
      sm: 6,
      lg: 6,
      xl: 3,
    },
    {
      type: 'text',
      name: 'origin',
      label: 'Origem',
      sm: 6,
      lg: 6,
      xl: 3,
    },
    {
      type: 'date',
      name: 'departure',
      label: 'Partida',
      sm: 6,
      lg: 6,
      xl: 2,
    },
    {
      type: 'time',
      name: 'start',
      label: 'Horário de partida',
      sm: 6,
      lg: 6,
      xl: 2,
    },
    {
      type: 'text',
      name: 'descriptionPayment',
      label: 'Forma de pagamento',
      sm: 6,
      lg: 6,
      xl: 2,
    },
    {
      type: 'text',
      name: 'stationDestiny',
      label: 'Estação de destino',
      sm: 6,
      lg: 6,
      xl: 3,
    },
    {
      type: 'text',
      name: 'destiny',
      label: 'Destino',
      sm: 6,
      lg: 6,
      xl: 3,
    },
    {
      type: 'date',
      name: 'arrival',
      label: 'Chegada',
      sm: 6,
      lg: 6,
      xl: 2,
    },
    {
      type: 'time',
      name: 'end',
      label: 'Horário de chegada',
      sm: 6,
      lg: 6,
      xl: 2,
    },
    {
      type: 'textarea',
      name: 'importantInformation',
      label: 'Informações importantes',
      sm: 12,
      lg: 12,
      xl: 12,
    },
    {
      type: 'inputFile',
      name: 'files',
      label: 'Anexos',
      max: 5,
      sm: 12,
      lg: 12,
    },
  ],
  transfer: [
    {
      type: 'text',
      name: 'originAddress',
      label: 'Local de partida',
      required: true,
      sm: 6,
      lg: 6,
      xl: 4,
    },
    {
      type: 'city',
      name: 'origin',
      label: 'Cidade do transfer',
      sm: 6,
      lg: 6,
      xl: 2,
    },
    {
      type: 'date',
      name: 'dateCheckIn',
      label: 'Início',
      sm: 2,
      lg: 2,
      xl: 2,
    },
    {
      type: 'time',
      name: 'timeCheckIn',
      label: 'Horário do início',
      sm: 2,
      lg: 2,
      xl: 2,
    },
    {
      type: 'number',
      min: 0,
      name: 'value',
      label: 'Valor',
      sm: 6,
      lg: 6,
      xl: 2,
    },
    {
      type: 'text',
      name: 'destinyAddress',
      label: 'Local de chegada',
      sm: 6,
      lg: 6,
      xl: 4,
    },
    {
      type: 'city',
      name: 'destiny',
      label: 'Cidade da chegada',
      sm: 6,
      lg: 6,
      xl: 2,
    },
    {
      type: 'date',
      name: 'dateCheckOut',
      label: 'Fim',
      sm: 2,
      lg: 2,
      xl: 2,
    },
    {
      type: 'time',
      name: 'timeCheckOut',
      label: 'Horário do fim',
      sm: 2,
      lg: 2,
      xl: 2,
    },
    {
      type: 'text',
      name: 'descriptionPayment',
      label: 'Forma de pagamento',
      // required: true,
      sm: 6,
      lg: 6,
      xl: 2,
    },
    {
      type: 'textarea',
      name: 'importantInformation',
      label: 'Informações importantes',
      sm: 12,
      lg: 12,
      xl: 12,
    },
    {
      type: 'inputFile',
      name: 'files',
      label: 'Anexos',
      max: 5,
      sm: 12,
      lg: 12,
    },
  ],
  vehicleRental: [
    {
      type: 'text',
      name: 'provider',
      label: 'Locadora',
      required: true,
      sm: 3,
      md: 3,
      lg: 3,
      xl: 3,
    },
    {
      type: 'number',
      min: 0,
      name: 'value',
      label: 'Valor',
      sm: 3,
      lg: 3,
      xl: 3,
    },
    {
      type: 'text',
      name: 'descriptionPayment',
      label: 'Forma de pagamento',
      sm: 3,
      lg: 3,
      xl: 3,
    },
    {
      type: 'select',
      name: 'type',
      maxLength: 100,
      label: 'Categoria do veículo',
      options: [
        { label: 'ECAR', value: 'ECAR' },
        { label: 'CCAR', value: 'CCAR' },
        { label: 'ICAR', value: 'ICAR' },
        { label: 'SCAR', value: 'SCAR' },
        { label: 'FCAR', value: 'FCAR' },
        { label: 'IFAR', value: 'IFAR' },
        { label: 'PCAR', value: 'PCAR' },
        { label: 'LCAR', value: 'LCAR' },
        { label: 'STAR', value: 'STAR' },
        { label: 'PFAR', value: 'PFAR' },
        { label: 'FVAR', value: 'FVAR' },
        { label: 'RFAR', value: 'RFAR' },
        { label: 'MVAR', value: 'MVAR' },
        { label: 'SFAR', value: 'SFAR' },
        { label: 'JCAE', value: 'JCAE' },
        { label: 'UGAR', value: 'UGAR' },
        { label: 'PGAR', value: 'PGAR' },
        { label: 'IXAE', value: 'IXAE' },
        { label: 'UFAR', value: 'UFAR' },
        { label: 'SSAR', value: 'SSAR' },
        { label: 'PVAR', value: 'PVAR' },
        { label: 'FFAR', value: 'FFAR' },
        { label: 'SVAR', value: 'SVAR' },
      ],
      sm: 3,
      md: 3,
      lg: 3,
      xl: 3,
    },
    {
      type: 'text',
      name: 'originAddress',
      label: 'Endereço de locação',
      sm: 8,
      md: 8,
      lg: 8,
      xl: 8,
    },
    {
      type: 'date',
      name: 'dateCheckIn',
      label: 'Data de locação',
      sm: 2,
      md: 2,
      lg: 2,
      xl: 2,
    },
    {
      type: 'time',
      name: 'timeCheckIn',
      label: 'Horário de locação',
      sm: 2,
      md: 2,
      lg: 2,
      xl: 2,
    },
    {
      type: 'text',
      name: 'destinyAddress',
      label: 'Endereço de devolução',
      sm: 8,
      md: 8,
      lg: 8,
      xl: 8,
    },
    {
      type: 'date',
      name: 'dateCheckOut',
      label: 'Data de devolução',
      sm: 2,
      md: 2,
      lg: 2,
      xl: 2,
    },
    {
      type: 'time',
      name: 'timeCheckOut',
      label: 'Horário de devolução',
      sm: 2,
      md: 2,
      lg: 2,
      xl: 2,
    },
    {
      type: 'textarea',
      name: 'importantInformation',
      label: 'Informações importantes',
      sm: 12,
      lg: 12,
      xl: 12,
    },
    {
      type: 'inputFile',
      name: 'files',
      label: 'Anexos',
      max: 5,
      sm: 12,
      lg: 12,
    },
  ],
}
