import styled from 'styled-components'
import AnalyticsContainer from '~/components/Analytics/Container'
import AnalyticsLineChart from '~/components/Analytics/LineChart'
import HomeDashboardTitle from '../Title'

export default function HomeDashboardLineChartWhatsapp({ data }) {
  return (
    <Container>
      <HomeDashboardTitle title={'Atendimento de Whatsapp'} />

      <AnalyticsContainer>
        <AnalyticsLineChart
          options={{
            responsive: true,
            plugins: {
              legend: {
                position: 'top',
              },
              title: {
                display: true,
                text: 'Chart.js Line Chart',
              },
            },
          }}
          data={data}
        />
      </AnalyticsContainer>
    </Container>
  )
}

const Container = styled.div`
  width: calc(50% - 16px);
  min-width: 540px;
  max-width: 100%;
  padding: 4px;
  height: 360px;
  display: flex;
  flex-direction: column;
`
