import { useState } from 'react'
import styled from 'styled-components'
import { getDateFormated, getStartEndLast6Month } from '~/functions/Date'
import useAgencyHome from '../dataHooks/useAgencyHome'
import HomeDashboardBanner from './Banner'
import HomeDashboardCalendar from './Calendar'
import HomeDashboardIndicators from './Indicators'
import HomeDashboardLineChartWhatsapp from './LineChartWhatsApp'
import HomeDashboardTableBirthdays from './TableBirthdays'
import HomeDashboardTableFlights from './TableFlights'
import HomeDashboardTableTasks from './TableTasks'

const DEFAULT_DATES = getStartEndLast6Month()

const DEFAULT_FILTERS = {
  branchs: [],
  start: getDateFormated(DEFAULT_DATES.start, 'YYYY-MM-DD'),
  end: getDateFormated(DEFAULT_DATES.end, 'YYYY-MM-DD'),
}

export default function HomeDashboard() {
  const [filters, setFilters] = useState(DEFAULT_FILTERS)
  const {
    loading,
    dashboards,
    embarques,
    chatCenterChart,
    reminders,
    birthdays,
    markTaskAsDone,
    sendHappyBirthdayMessageByWpp,
    allowHappyBirthdayMessage,
  } = useAgencyHome({
    filters: filters,
  })

  async function onChangeFilters(e) {
    setFilters(state => ({
      ...state,
      ...e,
    }))
  }

  return (
    <Container>
      <HomeDashboardBanner />

      <HomeDashboardCalendar
        filters={filters}
        onChangeFilters={onChangeFilters}
        defaultFilters={DEFAULT_FILTERS}
      />

      <HomeDashboardIndicators dashboards={dashboards} loading={loading} />

      <HomeDashboardTableFlights embarques={embarques} loading={loading} />

      <HomeDashboardLineChartWhatsapp data={chatCenterChart} />

      <HomeDashboardTableTasks
        tasks={reminders}
        loading={loading}
        markTaskAsDone={markTaskAsDone}
      />

      <HomeDashboardTableBirthdays
        birthdays={birthdays}
        loading={loading}
        sendHappyBirthdayMessageByWpp={sendHappyBirthdayMessageByWpp}
        allowHappyBirthdayMessage={allowHappyBirthdayMessage}
      />
    </Container>
  )
}

const Container = styled.div`
  min-width: 768px;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  margin: 0 auto;
`
