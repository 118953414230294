import { apiRequest } from '~/apiService'

export const getData = async ({
  setLongLink,
  setLeadFormOptions,
  setCampaignOptions,
  setProductOptions,
  setTagOptions,
}) => {
  const [
    responseLinkRequest,
    responseLeadFormRequest,
    responseCampaignOptionsRequest,
    responseProductOptionsRequest,
    responseTagOptionsRequest,
  ] = await Promise.allSettled([
    apiRequest('get', '/customer/request-link', {}),
    apiRequest('options', '/customer/leadForm', {}),
    apiRequest('options', '/campaign', {}),
    apiRequest('options', '/product', {}),
    apiRequest('options', '/agency/tags', {}),
  ])
  setLongLink(responseLinkRequest?.value?.data)
  setLeadFormOptions(responseLeadFormRequest?.value?.data)
  setTagOptions(responseTagOptionsRequest?.value?.data)

  setCampaignOptions(
    [
      {
        label: 'Nenhum',
        value: null,
      },
    ].concat(responseCampaignOptionsRequest?.value?.data),
  )

  setProductOptions(
    [
      {
        label: 'Nenhum',
        value: null,
      },
    ].concat(responseProductOptionsRequest?.value?.data),
  )
}

export const copyTravelLink = link => {
  navigator.clipboard.writeText(link)
}

export const redirectToTravelLink = link => {
  var win = window.open(link, '_blank')
  win.focus()
}

export const onSubmit = async (data, setShortLink) => {
  const body = {
    ...data,
    tags: data.tags?.map(tag => tag.label),
  }

  const result = await apiRequest('post', '/customerForm', body)
  setShortLink(result.data)
}
