import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { getDateFull } from '~/functions/Date'
import HomeDashboardTitle from '../Title'

export default function HomeDashboardBanner() {
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [selectedData, setSelectedData] = useState()
  const [bannerTransform, setBannerTransform] = useState('translateX(0%)')
  const [bannerOpacity, setBannerOpacity] = useState('1')
  const [data, setData] = useState([
    {
      imageUrl:
        'https://tripmee.com.br/cache/arquivos/imagens/blog/noticia8_720y80cc500.png',
      title: 'Benefícios da Automação para Agências de Viagens',
      desc: 'A automação está transformando a maneira como as agências de viagens operam, permitindo que os agentes se concentrem em atividades estratégicas e no atendimento ao cliente.',
    },
    {
      imageUrl:
        'https://tripmee.com.br/cache/arquivos/imagens/blog/atendimento_720y80cc500.png',
      title: 'A Evolução do Atendimento ao Cliente no Turismo',
      desc: 'O atendimento ao cliente no setor de turismo tem evoluído significativamente ao longo dos anos, impulsionado por mudanças tecnológicas e expectativas crescentes dos viajantes.',
    },
    {
      imageUrl:
        'https://tripmee.com.br/cache/arquivos/imagens/blog/noticia4_720y80cc500.png',
      title: 'Como Criar Roteiros de Viagem Inesquecíveis',
      desc: 'Criar roteiros de viagem inesquecíveis é uma das principais responsabilidades de um agente de viagens. Um bom roteiro não só atende às expectativas do cliente, mas também supera essas expectativas.',
    },
    {
      imageUrl:
        'https://tripmee.com.br/cache/arquivos/imagens/blog/noticia5_720y80cc500.png',
      title: 'Utilizando Big Data para Melhorar o Planejamento de Viagens',
      desc: 'A era digital trouxe consigo uma avalanche de dados, e o setor de turismo não ficou para trás. O Big Data está transformando a maneira como as viagens são planejadas e executadas, permitindo uma compreensão mais profunda das preferências dos viajantes e melhorando a eficiência operacional.',
    },
  ])

  useEffect(() => {
    setInterval(() => handleNext(), 10000)
  }, [])

  useEffect(() => {
    setSelectedData(data[selectedIndex])
  }, [selectedIndex])

  async function handlePrevious() {
    setBannerOpacity('0')
    setBannerTransform('translateX(50%)')

    setTimeout(() => {
      setBannerTransform('translateX(-50%)')
      setBannerOpacity('0')
    }, 125)

    setTimeout(() => {
      setSelectedIndex(state => {
        const newSelectedIndex = state - 1

        if (newSelectedIndex < 0) {
          return data.length - 1
        }

        return newSelectedIndex
      })

      setBannerTransform('translateX(0%)')
      setBannerOpacity('1')
    }, 250)
  }

  async function handleNext() {
    setBannerOpacity('0')
    setBannerTransform('translateX(-50%)')

    setTimeout(() => {
      setBannerTransform('translateX(50%)')
      setBannerOpacity('0')
    }, 125)

    setTimeout(() => {
      setSelectedIndex(state => {
        const newSelectedIndex = state + 1

        if (newSelectedIndex > data.length - 1) {
          return 0
        }

        return newSelectedIndex
      })

      setBannerTransform('translateX(0%)')
      setBannerOpacity('1')
    }, 250)
  }

  return (
    <Container>
      <HomeDashboardTitle title={`Hoje é ${getDateFull().full}`} />

      {selectedData && (
        <div className='home-dashboard-banner-info-container'>
          {selectedData.imageUrl && (
            <img
              className='home-dashboard-banner-img'
              src={selectedData.imageUrl}
              style={{
                transform: bannerTransform,
                opacity: bannerOpacity,
                transition: '250ms',
              }}
            />
          )}

          <div
            className='home-dashboard-banner-info'
            style={{ opacity: bannerOpacity }}
          >
            {selectedData.title && (
              <span className='home-dashboard-banner-title'>
                {selectedData.title}
              </span>
            )}

            {selectedData.desc && (
              <span className='home-dashboard-banner-desc'>
                {selectedData.desc}
              </span>
            )}
          </div>
        </div>
      )}

      <button
        className='home-dashboard-banner-previous-button'
        type='button'
        onClick={handlePrevious}
      >
        <i className='fas fa-arrow-left' />
      </button>

      <button
        className='home-dashboard-banner-next-button'
        type='button'
        onClick={handleNext}
      >
        <i className='fas fa-arrow-right' />
      </button>

      <div className='home-dashboard-banner-dots'>
        {data.map((e, i) => {
          return (
            <button
              key={`home-dashboard-banner-dots-key-` + i}
              id={`home-dashboard-banner-dots-id-` + i}
              className='home-dashboard-banner-dots-button'
              onClick={() => setSelectedData(e)}
            >
              <i
                className={`far fa-${
                  selectedData && e.title === selectedData.title ? 'dot-' : ''
                }circle`}
              />
            </button>
          )
        })}
      </div>
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  width: calc(50% - 8px);
  min-width: 540px;
  max-width: 100%;
  padding: 4px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
    height: 318px;
  }

  .home-dashboard-banner-info-container {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    overflow: hidden;
  }

  .home-dashboard-banner-info {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4px;
    margin: 4px;
    padding: 24px 32px;
    border-radius: 8px;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 1));
    color: #fff;
  }

  .home-dashboard-banner-dots {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 4px;
    position: absolute;
    margin: 4px;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .home-dashboard-banner-dots-button {
    background: none;
    border: none;
    color: #fff;
    font-size: 12px;
  }

  .home-dashboard-banner-title {
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    width: 70%;
  }

  .home-dashboard-banner-desc {
    font-size: 12px;
    font-weight: 500;
    text-align: justify;
  }

  .home-dashboard-banner-previous-button,
  .home-dashboard-banner-next-button {
    background: none;
    border: none;
    padding: 16px;
    color: #fff;
    font-size: 18px;
    height: 318px;
  }

  .home-dashboard-banner-previous-button {
    position: absolute;
    left: 4px;
    bottom: 4px;
  }

  .home-dashboard-banner-next-button {
    position: absolute;
    right: 4px;
    bottom: 4px;
  }

  .home-dashboard-indicators-title {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
  }

  .home-dashboard-indicators-title-info {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 8px;
  }
`
