import ModalDefault from '~/components/ModalDefault'
import DefaultForm from '~/components/DefaultForm'
import { apiRequest } from '~/apiService'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { getFile } from '~/functions/File'

export default function Modal({
  title = '',
  origin = '',
  id = 0,
  proposalId = 0,
  inputs = [],
  handle = () => {},
  content = '',
  setData,
}) {
  const [loading, setLoading] = useState(false)
  const [defaultValue, setDefaultValue] = useState({})
  const [operation] = useState(id == 0 ? 'post' : 'put')

  useEffect(() => {
    if (operation == 'put') get()
  }, [])

  const get = async () => {
    const result = await apiRequest(
      'get',
      `proposal/${origin}/${id}`,
      {},
      setLoading,
    )

    if (origin == 'destiny') {
      result.data.content.name = {
        label: result.data.content.name,
        value: '',
      }
    }

    const newResultHotel = {
      ...result?.data?.content,
      files: [],
    }

    if (result?.data?.content?.files?.length > 0) {
      for (let iF = 0; iF < result?.data?.content?.files?.length; iF++) {
        const file = await getFile(
          result?.data?.content?.files[iF]?.file,
          result?.data?.content?.files[iF]?.fileName,
          result?.data?.content?.files[iF]?.contentType,
        )

        newResultHotel?.files.push(file)
      }
    }

    setDefaultValue(newResultHotel)

    result.error && toast.info(result?.erroMessage)
  }

  const onSubmit = async data => {
    const formData = new FormData()
    for (let key in data) {
      if (data[key] != null) {
        if (key == 'files') {
          data?.files?.map(file => formData.append('files', file))
        } else {
          if (typeof data[key] == 'object') {
            formData.append(key, data[key]?.label)
          } else {
            formData.append(key, data[key])
          }
        }
      }
    }

    formData.set('proposalId', proposalId)

    const result = await apiRequest(
      operation,
      `proposal/${origin}`,
      formData,
      setLoading,
    )

    setData(state => {
      const aux = { ...state }

      aux[content] = result?.data?.content

      return aux
    })

    handle()

    !result.error && toast.success(result?.data?.message)

    result.error && toast.info(result?.erroMessage)
  }

  return (
    <>
      <ModalDefault
        title={title}
        handleShow={handle}
        content={
          <div>
            <DefaultForm
              callbackSubmit={onSubmit}
              callbackReset={() => {}}
              defaultValue={defaultValue}
              disabled={loading}
              loading={loading}
              inputs={inputs}
            />
          </div>
        }
      />
    </>
  )
}
