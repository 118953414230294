import styled from 'styled-components'
import AnalyticsTable from '~/components/Analytics/Table'
import IconButton from '~/components/IconButton'
import HomeDashboardTitle from '../Title'

export default function HomeDashboardTableTasks({
  tasks,
  loading,
  markTaskAsDone,
}) {
  return (
    <Container>
      <HomeDashboardTitle title={'Lista de tarefas'} />

      <AnalyticsTable
        data={tasks}
        loading={loading}
        thin={true}
        columns={[
          {
            Header: '',
            accessor: 'reminderDone',
            width: 20,
            Cell: props => (
              <IconButton
                id={props.row.index}
                type={'user'}
                tooltipOverride={
                  props.row.original.reminderDone
                    ? 'Desmarcar'
                    : 'Marcar como feito'
                }
                iconOverride={
                  props.row.original.reminderDone
                    ? 'fas fa-check-circle'
                    : 'fas fa-clock'
                }
                colorOverride={
                  props.row.original.reminderDone ? 'green' : '#333'
                }
                onClick={() => markTaskAsDone(props.row.original.id)}
              />
            ),
          },
          {
            Header: 'Tarefa',
            accessor: 'title',
            width: 250,
          },
          {
            Header: 'Data',
            accessor: 'shootingData',
            width: 100,
          },
          {
            Header: 'Responsável',
            accessor: 'agent',
            width: 180,
          },
        ]}
      />
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(50% - 8px);
  min-width: 540px;
  max-width: 100%;
  padding: 4px;

  .table-responsive {
    max-height: 200px;
    overflow: auto;
  }

  @media (max-width: 920px) {
    height: auto;
  }
`
