import DefaultLayout from '~/pages/__layouts__/DefaultLayout'
import { Outlet, Route } from 'react-router-dom'
import HomeDashboard from './Dashboard'

export default function Home() {
  return (
    <Route
      path='/schedule'
      element={
        <DefaultLayout>
          <Outlet />
        </DefaultLayout>
      }
    >
      <Route index element={<HomeDashboard />} />
    </Route>
  )
}
