import IconButton from '~/components/IconButton'

export const proposalInputs = (
  loading,
  setShowModal,
  onRemove,
  onClone,
  observation,
) => {
  const aux = [
    {
      type: 'text',
      name: 'title',
      label: 'Título',
      required: true,
      sm: 12,
      lg: 9,
      xl: 3,
    },
    {
      type: 'city',
      name: 'origin',
      label: 'Origem',
      required: true,
      sm: 12,
      lg: 3,
      xl: 2,
    },
    {
      type: 'date',
      name: 'dateInitial',
      label: 'Data Inicial da viagem',
      required: true,
      sm: 12,
      lg: 3,
      xl: 2,
    },
    {
      type: 'date',
      name: 'dateFinal',
      label: 'Data Final da viagem',
      required: true,
      sm: 12,
      lg: 3,
      xl: 2,
    },
    {
      type: 'select',
      name: 'proposalStatus',
      label: 'Status',
      options: [
        { label: 'Aprovado', value: 'Aprovado' },
        { label: 'Em análise', value: 'Em análise' },
        { label: 'Cancelada', value: 'Cancelada' },
        { label: 'Em preparação', value: 'Em preparação' },
      ],
      sm: 12,
      lg: 3,
      xl: 2,
    },
    {
      type: 'number',
      min: 0,
      name: 'fullValue',
      label: 'Valor',
      sm: 12,
      lg: 3,
      xl: 1,
    },
    {
      type: 'html',
      name: 'description',
      label: 'Sobre a viagem',
      maxLength: 15000,
      required: true,
      sm: 12,
      lg: 6,
      xl: 6,
    },
    {
      type: 'html',
      name: 'observation',
      label: 'Observações',
      sm: 12,
      lg: 6,
      xl: 6,
    },
    {
      type: 'image',
      name: 'avatarProposal',
      label: 'Imagem de capa',
      sm: 12,
      lg: 6,
      xl: 6,
    },
    {
      type: 'image',
      name: 'avatarProposal2',
      label: 'Imagem sobre a viagem',
      sm: 12,
      lg: 6,
      xl: 6,
    },
    {
      type: 'check',
      name: 'allValues',
      label: 'Mostrar todos os valores',
      sm: 2,
      lg: 2,
      xl: 2,
    },
    {
      type: 'check',
      name: 'dolar',
      label: 'Mostrar todos os valores em dólar',
      sm: 3,
      lg: 3,
      xl: 2,
    },
    {
      type: 'check',
      name: 'showTotalValue',
      label: 'Não mostrar o valor total',
      sm: 3,
      lg: 3,
      xl: 2,
    },
    {
      type: 'check',
      name: 'calculateValueAuto',
      label: 'Calcular valor automaticamente',
      sm: 2,
      lg: 2,
      xl: 2,
    },
    {
      type: 'tableToList',
      name: 'proposalDestiny',
      label: 'Tabela de destinos',
      nameButton: 'Novo destino',
      handle: () =>
        setShowModal(state => ({
          ...state,
          destiny: { ...state.destiny, openModal: !state.destiny.openModal },
        })),
      loading: loading,
      columns: [
        {
          Header: 'Data inicial',
          accessor: 'dateStart',
          width: 125,
        },
        {
          Header: 'Data final',
          accessor: 'dateEnd',
          width: 125,
        },
        {
          Header: 'Nome',
          accessor: 'name',
          width: 200,
        },
        {
          Header: '',
          accessor: 'edit',
          Cell: props => {
            return (
              <IconButton
                id={props.row.original.id}
                type={'details'}
                onClick={() => {
                  setShowModal(state => ({
                    ...state,
                    destiny: {
                      ...state.destiny,
                      openModal: !state.destiny.openModal,
                      id: props.row.original.id,
                    },
                  }))
                }}
              />
            )
          },
          width: 20,
        },
        {
          Header: '',
          accessor: 'clone',
          Cell: props => {
            return (
              <IconButton
                id={props.row.original.id}
                type={'clone'}
                iconOverride={'far fa-clone'}
                tooltipOverride={'Clonar'}
                onClick={() =>
                  onClone('destiny', props.row.original.id, 'proposalDestiny')
                }
              />
            )
          },
          width: 20,
        },
        {
          Header: '',
          accessor: 'remove',
          Cell: props => {
            return (
              <IconButton
                id={props.row.original.id}
                type={'remove'}
                onClick={() => {
                  onRemove('destiny', props.row.original.id, 'proposalDestiny')
                }}
              />
            )
          },
          width: 20,
        },
      ],
      sm: 6,
      lg: 6,
      xl: 6,
    },
    {
      type: 'tableToList',
      name: 'proposalFlight',
      label: 'Tabela de aéreos',
      nameButton: 'Novo aéreo',
      handle: () =>
        setShowModal(state => ({
          ...state,
          flight: { ...state.flight, openModal: !state.flight.openModal },
        })),
      loading: loading,
      columns: [
        {
          Header: 'Forma de pagamento',
          accessor: 'descriptionPayment',
          width: 150,
        },
        {
          Header: 'Valor',
          accessor: 'value',
          width: 100,
        },
        {
          Header: 'Trechos',
          accessor: 'excerpts',
          width: 70,
        },
        {
          Header: 'Trajeto',
          accessor: 'excerptsDetails',
          width: 200,
        },
        {
          Header: '',
          accessor: 'edit',
          Cell: props => {
            return (
              <IconButton
                id={props.row.original.id}
                type={'details'}
                onClick={() => {
                  setShowModal(state => ({
                    ...state,
                    flight: {
                      ...state.flight,
                      openModal: !state.flight.openModal,
                      id: props.row.original.id,
                    },
                  }))
                }}
              />
            )
          },
          width: 20,
        },
        {
          Header: '',
          accessor: 'clone',
          Cell: props => {
            return (
              <IconButton
                id={props.row.original.id}
                type={'clone'}
                iconOverride={'far fa-clone'}
                tooltipOverride={'Clonar'}
                onClick={() =>
                  onClone('flight', props.row.original.id, 'proposalFlight')
                }
              />
            )
          },
          width: 20,
        },
        {
          Header: '',
          accessor: 'remove',
          Cell: props => {
            return (
              <IconButton
                id={props.row.original.id}
                type={'remove'}
                onClick={() => {
                  onRemove('flight', props.row.original.id, 'proposalFlight')
                }}
              />
            )
          },
          width: 20,
        },
      ],
      sm: 6,
      lg: 6,
      xl: 6,
    },
    {
      type: 'tableToList',
      name: 'proposalNautical',
      label: 'Tabela de náuticos',
      nameButton: 'Novo náutico',
      handle: () =>
        setShowModal(state => ({
          ...state,
          nautical: { ...state.nautical, openModal: !state.nautical.openModal },
        })),
      loading: loading,
      columns: [
        {
          Header: 'Origem',
          accessor: 'origin',
          width: 125,
        },
        {
          Header: 'Destino',
          accessor: 'destiny',
          width: 125,
        },
        {
          Header: 'Valor',
          accessor: 'value',
          width: 100,
        },
        {
          Header: '',
          accessor: 'edit',
          Cell: props => {
            return (
              <IconButton
                id={props.row.original.id}
                type={'details'}
                onClick={() => {
                  setShowModal(state => ({
                    ...state,
                    nautical: {
                      ...state.nautical,
                      openModal: !state.nautical.openModal,
                      id: props.row.original.id,
                    },
                  }))
                }}
              />
            )
          },
          width: 20,
        },
        {
          Header: '',
          accessor: 'clone',
          Cell: props => {
            return (
              <IconButton
                id={props.row.original.id}
                type={'clone'}
                iconOverride={'far fa-clone'}
                tooltipOverride={'Clonar'}
                onClick={() =>
                  onClone('nautical', props.row.original.id, 'proposalNautical')
                }
              />
            )
          },
          width: 20,
        },
        {
          Header: '',
          accessor: 'remove',
          Cell: props => {
            return (
              <IconButton
                id={props.row.original.id}
                type={'remove'}
                onClick={() => {
                  onRemove(
                    'nautical',
                    props.row.original.id,
                    'proposalNautical',
                  )
                }}
              />
            )
          },
          width: 20,
        },
      ],
      sm: 6,
      lg: 6,
      xl: 6,
    },
    {
      type: 'tableToList',
      name: 'proposalTrain',
      label: 'Tabela de trens',
      nameButton: 'Novo trem',
      handle: () =>
        setShowModal(state => ({
          ...state,
          train: { ...state.train, openModal: !state.train.openModal },
        })),
      loading: loading,
      columns: [
        {
          Header: 'Origem',
          accessor: 'origin',
          width: 125,
        },
        {
          Header: 'Destino',
          accessor: 'destiny',
          width: 125,
        },
        {
          Header: 'Valor',
          accessor: 'value',
          width: 100,
        },
        {
          Header: '',
          accessor: 'edit',
          Cell: props => {
            return (
              <IconButton
                id={props.row.original.id}
                type={'details'}
                onClick={() => {
                  setShowModal(state => ({
                    ...state,
                    train: {
                      ...state.train,
                      openModal: !state.train.openModal,
                      id: props.row.original.id,
                    },
                  }))
                }}
              />
            )
          },
          width: 20,
        },
        {
          Header: '',
          accessor: 'clone',
          Cell: props => {
            return (
              <IconButton
                id={props.row.original.id}
                type={'clone'}
                iconOverride={'far fa-clone'}
                tooltipOverride={'Clonar'}
                onClick={() =>
                  onClone('train', props.row.original.id, 'proposalTrain')
                }
              />
            )
          },
          width: 20,
        },
        {
          Header: '',
          accessor: 'remove',
          Cell: props => {
            return (
              <IconButton
                id={props.row.original.id}
                type={'remove'}
                onClick={() => {
                  onRemove('train', props.row.original.id, 'proposalTrain')
                }}
              />
            )
          },
          width: 20,
        },
      ],
      sm: 6,
      lg: 6,
      xl: 6,
    },
    {
      type: 'tableToList',
      name: 'proposalTransfer',
      label: 'Tabela de transfers',
      nameButton: 'Novo transfer',
      handle: () =>
        setShowModal(state => ({
          ...state,
          transfer: { ...state.transfer, openModal: !state.transfer.openModal },
        })),
      loading: loading,
      columns: [
        {
          Header: 'Início',
          accessor: 'dateCheckIn',
          width: 100,
        },
        {
          Header: 'Fim',
          accessor: 'dateCheckOut',
          width: 100,
        },
        {
          Header: 'Valor',
          accessor: 'value',
          width: 100,
        },
        {
          Header: '',
          accessor: 'edit',
          Cell: props => {
            return (
              <IconButton
                id={props.row.original.id}
                type={'details'}
                onClick={() => {
                  setShowModal(state => ({
                    ...state,
                    transfer: {
                      ...state.transfer,
                      openModal: !state.transfer.openModal,
                      id: props.row.original.id,
                    },
                  }))
                }}
              />
            )
          },
          width: 20,
        },
        {
          Header: '',
          accessor: 'clone',
          Cell: props => {
            return (
              <IconButton
                id={props.row.original.id}
                type={'clone'}
                iconOverride={'far fa-clone'}
                tooltipOverride={'Clonar'}
                onClick={() =>
                  onClone('transfer', props.row.original.id, 'proposalTransfer')
                }
              />
            )
          },
          width: 20,
        },
        {
          Header: '',
          accessor: 'remove',
          Cell: props => {
            return (
              <IconButton
                id={props.row.original.id}
                type={'remove'}
                onClick={() => {
                  onRemove(
                    'transfer',
                    props.row.original.id,
                    'proposalTransfer',
                  )
                }}
              />
            )
          },
          width: 20,
        },
      ],
      sm: 6,
      lg: 6,
      xl: 6,
    },
    {
      type: 'tableToList',
      name: 'proposalHotel',
      label: 'Tabela de hotéis',
      nameButton: 'Novo hotel',
      handle: () =>
        setShowModal(state => ({
          ...state,
          hotel: { ...state.hotel, openModal: !state.hotel.openModal },
        })),
      loading: loading,
      columns: [
        {
          Header: 'Nome',
          accessor: 'name',
          width: 300,
        },
        {
          Header: 'Valor',
          accessor: 'value',
          width: 150,
        },
        {
          Header: '',
          accessor: 'edit',
          Cell: props => {
            return (
              <IconButton
                id={props.row.original.id}
                type={'details'}
                onClick={() => {
                  setShowModal(state => ({
                    ...state,
                    hotel: {
                      ...state.hotel,
                      openModal: !state.hotel.openModal,
                      id: props.row.original.id,
                    },
                  }))
                }}
              />
            )
          },
          width: 20,
        },
        {
          Header: '',
          accessor: 'clone',
          Cell: props => {
            return (
              <IconButton
                id={props.row.original.id}
                type={'clone'}
                iconOverride={'far fa-clone'}
                tooltipOverride={'Clonar'}
                onClick={() =>
                  onClone('hotel', props.row.original.id, 'proposalHotel')
                }
              />
            )
          },
          width: 20,
        },
        {
          Header: '',
          accessor: 'remove',
          Cell: props => {
            return (
              <IconButton
                id={props.row.original.id}
                type={'remove'}
                onClick={() => {
                  onRemove('hotel', props.row.original.id, 'proposalHotel')
                }}
              />
            )
          },
          width: 20,
        },
      ],
      sm: 6,
      lg: 6,
      xl: 6,
    },
    {
      type: 'tableToList',
      name: 'proposalActivity',
      label: 'Tabela de atividades',
      nameButton: 'Nova atividade',
      handle: () =>
        setShowModal(state => ({
          ...state,
          activity: { ...state.activity, openModal: !state.activity.openModal },
        })),
      loading: loading,
      columns: [
        {
          Header: 'Local',
          accessor: 'startAddress',
          width: 130,
        },
        {
          Header: 'Dia',
          accessor: 'date',
          width: 130,
        },
        {
          Header: 'Valor',
          accessor: 'value',
          width: 130,
        },
        {
          Header: '',
          accessor: 'edit',
          Cell: props => {
            return (
              <IconButton
                id={props.row.original.id}
                type={'details'}
                onClick={() => {
                  setShowModal(state => ({
                    ...state,
                    activity: {
                      ...state.activity,
                      openModal: !state.activity.openModal,
                      id: props.row.original.id,
                    },
                  }))
                }}
              />
            )
          },
          width: 20,
        },
        {
          Header: '',
          accessor: 'clone',
          Cell: props => {
            return (
              <IconButton
                id={props.row.original.id}
                type={'clone'}
                iconOverride={'far fa-clone'}
                tooltipOverride={'Clonar'}
                onClick={() =>
                  onClone('activity', props.row.original.id, 'proposalActivity')
                }
              />
            )
          },
          width: 20,
        },
        {
          Header: '',
          accessor: 'remove',
          Cell: props => {
            return (
              <IconButton
                id={props.row.original.id}
                type={'remove'}
                onClick={() => {
                  onRemove(
                    'activity',
                    props.row.original.id,
                    'proposalActivity',
                  )
                }}
              />
            )
          },
          width: 20,
        },
      ],
      sm: 6,
      lg: 6,
      xl: 6,
    },
    {
      type: 'tableToList',
      name: 'proposalInsurance',
      label: 'Tabela de seguros',
      nameButton: 'Novo seguro',
      handle: () =>
        setShowModal(state => ({
          ...state,
          insurance: {
            ...state.insurance,
            openModal: !state.insurance.openModal,
          },
        })),
      loading: loading,
      columns: [
        {
          Header: 'Início',
          accessor: 'timeStart',
          width: 125,
        },
        {
          Header: 'Fim',
          accessor: 'timeEnd',
          width: 125,
        },
        {
          Header: 'Tipo de cobertura',
          accessor: 'typeCoverage',
          width: 150,
        },
        {
          Header: '',
          accessor: 'edit',
          Cell: props => {
            return (
              <IconButton
                id={props.row.original.id}
                type={'details'}
                onClick={() => {
                  setShowModal(state => ({
                    ...state,
                    insurance: {
                      ...state.insurance,
                      openModal: !state.insurance.openModal,
                      id: props.row.original.id,
                    },
                  }))
                }}
              />
            )
          },
          width: 20,
        },
        {
          Header: '',
          accessor: 'clone',
          Cell: props => {
            return (
              <IconButton
                id={props.row.original.id}
                type={'clone'}
                iconOverride={'far fa-clone'}
                tooltipOverride={'Clonar'}
                onClick={() =>
                  onClone(
                    'insurance',
                    props.row.original.id,
                    'proposalInsurance',
                  )
                }
              />
            )
          },
          width: 20,
        },
        {
          Header: '',
          accessor: 'remove',
          Cell: props => {
            return (
              <IconButton
                id={props.row.original.id}
                type={'remove'}
                onClick={() => {
                  onRemove(
                    'insurance',
                    props.row.original.id,
                    'proposalInsurance',
                  )
                }}
              />
            )
          },
          width: 20,
        },
      ],
      sm: 6,
      lg: 6,
      xl: 6,
    },
    {
      type: 'tableToList',
      name: 'proposalOtherService',
      label: 'Tabela de serviços',
      nameButton: 'Novo serviço',
      handle: () =>
        setShowModal(state => ({
          ...state,
          otherService: {
            ...state.otherService,
            openModal: !state.otherService.openModal,
          },
        })),
      loading: loading,
      columns: [
        {
          Header: 'Nome',
          accessor: 'title',
          width: 125,
        },
        {
          Header: 'Valor',
          accessor: 'value',
          width: 125,
        },
        {
          Header: '',
          accessor: 'edit',
          Cell: props => {
            return (
              <IconButton
                id={props.row.original.id}
                type={'details'}
                onClick={() => {
                  setShowModal(state => ({
                    ...state,
                    otherService: {
                      ...state.otherService,
                      openModal: !state.otherService.openModal,
                      id: props.row.original.id,
                    },
                  }))
                }}
              />
            )
          },
          width: 20,
        },
        {
          Header: '',
          accessor: 'clone',
          Cell: props => {
            return (
              <IconButton
                id={props.row.original.id}
                type={'clone'}
                iconOverride={'far fa-clone'}
                tooltipOverride={'Clonar'}
                onClick={() =>
                  onClone(
                    'otherService',
                    props.row.original.id,
                    'proposalOtherService',
                  )
                }
              />
            )
          },
          width: 20,
        },
        {
          Header: '',
          accessor: 'remove',
          Cell: props => {
            return (
              <IconButton
                id={props.row.original.id}
                type={'remove'}
                onClick={() => {
                  onRemove(
                    'otherService',
                    props.row.original.id,
                    'proposalOtherService',
                  )
                }}
              />
            )
          },
          width: 20,
        },
      ],
      sm: 6,
      lg: 6,
      xl: 6,
    },
    {
      type: 'tableToList',
      name: 'proposalTicket',
      label: 'Tabela de ingressos',
      nameButton: 'Novo ingresso',
      handle: () =>
        setShowModal(state => ({
          ...state,
          ticket: { ...state.ticket, openModal: !state.ticket.openModal },
        })),
      loading: loading,
      columns: [
        {
          Header: 'Titulo',
          accessor: 'title',
          width: 250,
        },
        {
          Header: 'Data',
          accessor: 'dateStart',
          width: 125,
        },
        {
          Header: 'Valor',
          accessor: 'value',
          width: 125,
        },
        {
          Header: '',
          accessor: 'edit',
          Cell: props => {
            return (
              <IconButton
                id={props.row.original.id}
                type={'details'}
                onClick={() => {
                  setShowModal(state => ({
                    ...state,
                    ticket: {
                      ...state.ticket,
                      openModal: !state.ticket.openModal,
                      id: props.row.original.id,
                    },
                  }))
                }}
              />
            )
          },
          width: 20,
        },
        {
          Header: '',
          accessor: 'clone',
          Cell: props => {
            return (
              <IconButton
                id={props.row.original.id}
                type={'clone'}
                iconOverride={'far fa-clone'}
                tooltipOverride={'Clonar'}
                onClick={() =>
                  onClone('ticket', props.row.original.id, 'proposalTicket')
                }
              />
            )
          },
          width: 20,
        },
        {
          Header: '',
          accessor: 'remove',
          Cell: props => {
            return (
              <IconButton
                id={props.row.original.id}
                type={'remove'}
                onClick={() => {
                  onRemove('ticket', props.row.original.id, 'proposalTicket')
                }}
              />
            )
          },
          width: 20,
        },
      ],
      sm: 6,
      lg: 6,
      xl: 6,
    },
    {
      type: 'tableToList',
      name: 'proposalVehicleRental',
      label: 'Tabela de locações veiculares',
      nameButton: 'Nova locação veicular',
      handle: () =>
        setShowModal(state => ({
          ...state,
          vehicleRental: {
            ...state.vehicleRental,
            openModal: !state.vehicleRental.openModal,
          },
        })),
      loading: loading,
      columns: [
        {
          Header: 'Categoria',
          accessor: 'type.label',
          width: 125,
        },
        {
          Header: 'Data',
          accessor: 'dateCheckIn',
          width: 125,
        },
        {
          Header: 'Valor',
          accessor: 'value',
          width: 125,
        },
        {
          Header: '',
          accessor: 'edit',
          Cell: props => {
            return (
              <IconButton
                id={props.row.original.id}
                type={'details'}
                onClick={() => {
                  setShowModal(state => ({
                    ...state,
                    vehicleRental: {
                      ...state.vehicleRental,
                      openModal: !state.vehicleRental.openModal,
                      id: props.row.original.id,
                    },
                  }))
                }}
              />
            )
          },
          width: 20,
        },
        {
          Header: '',
          accessor: 'clone',
          Cell: props => {
            return (
              <IconButton
                id={props.row.original.id}
                type={'clone'}
                iconOverride={'far fa-clone'}
                tooltipOverride={'Clonar'}
                onClick={() =>
                  onClone(
                    'vehicleRental',
                    props.row.original.id,
                    'proposalVehicleRental',
                  )
                }
              />
            )
          },
          width: 20,
        },
        {
          Header: '',
          accessor: 'remove',
          Cell: props => {
            return (
              <IconButton
                id={props.row.original.id}
                type={'remove'}
                onClick={() => {
                  onRemove(
                    'vehicleRental',
                    props.row.original.id,
                    'proposalVehicleRental',
                  )
                }}
              />
            )
          },
          width: 20,
        },
      ],
      sm: 6,
      lg: 6,
      xl: 6,
    },
    {
      empty: true,
      text: '',
      sm: 6,
    },
    {
      type: 'html',
      name: 'included',
      label: 'Nosso roteiro inclui',
      options: observation,
      maxLength: 15000,
      sm: 6,
      lg: 6,
      xl: 6,
    },
    {
      type: 'html',
      name: 'notIncluded',
      label: 'Nosso roteiro não inclui',
      options: observation,
      sm: 6,
      lg: 6,
      xl: 6,
    },
    {
      type: 'html',
      name: 'alter',
      label: 'Condições de alterações',
      options: observation,
      sm: 6,
      lg: 6,
      xl: 6,
    },
    {
      type: 'html',
      name: 'cancel',
      label: 'Condições de cancelamento',
      options: observation,
      sm: 6,
      lg: 6,
      xl: 6,
    },
    {
      type: 'html',
      name: 'reservation',
      label: 'Condições de reserva',
      options: observation,
      sm: 6,
      lg: 6,
      xl: 6,
    },
    {
      type: 'html',
      name: 'countryExi',
      label: 'Exigências dos países',
      options: observation,
      sm: 6,
      lg: 6,
      xl: 6,
    },
    {
      empty: true,
      text: `
        <b style='font-size: 12px'>
          **Para otimizar a visualização das imagens nos itens da prosposta, segue as dimensões ideias: <br/>
          1 imagem (1 de 1200x500),
          2 imagens (2 de 600x500),
          3 imagens (1 de 600x500 e 2 de 600x250),
          4 imagens (4 de 600x250),
          5 imagens (1 de 600x500 e 4 de 300x250).
        </b>
      `,
      sm: 12,
      lg: 12,
      xl: 12,
    },
  ]

  return aux
}
