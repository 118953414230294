function removeSpace(value) {
  if (value === null) {
    return ''
  }
  return value.replace(' ', '')
}

function splitPaxName(completeName) {
  if (completeName) {
    const normalizedName = completeName.split('/').reverse().join(' ')

    const arrayNames = normalizedName.split(' ')

    return `${arrayNames[0]} ${arrayNames[1] ?? ''} ${arrayNames[2] ?? ''}`
  } else return completeName
}

function verifyDefaultPass(value) {
  return (
    value.length > 7 &&
    value.length < 13 &&
    value.match(/\d/g) &&
    value.match(/[a-z]/gi)
  )
}

function getCapitalize(word) {
  return word.replace(/\w/, first => first.toUpperCase())
}

function getFirstWords(text, count = 3) {
  if (text) {
    const textSplited = text.split(' ')

    const textSliced = textSplited.slice(0, count)

    let res = ''

    textSliced.map(e => {
      res += e + ' '
    })

    return `${res}${textSplited.length > count ? '...' : ''}`
  }

  return text
}

function isImageUrl(url) {
  return url && url.startsWith('http')
}

export {
  removeSpace,
  splitPaxName,
  verifyDefaultPass,
  getCapitalize,
  getFirstWords,
  isImageUrl,
}
