import IconButton from '~/components/IconButton'
import DefaultForm from '~/components/DefaultForm'
import { onSubmit } from './functions'
import { inputs } from './inputs'

export const LongLink = ({
  copyTravelLink,
  redirectToTravelLink,
  longLink,
}) => {
  return (
    <>
      <b>Link para cadastro completo</b>
      <input
        style={{
          height: '38px',
          borderRadius: '5px',
          border: '1px solid #ccc',
          width: '90%',
          marginRight: '2%',
        }}
        type='text'
        label='Link para o Viajante'
        name={'link'}
        value={longLink}
      />
      <IconButton
        iconOverride={'fas fa-copy'}
        tooltipOverride={'Copiar'}
        onClick={() => copyTravelLink(longLink)}
      />
      <IconButton
        iconOverride={'fas fa-external-link-alt'}
        tooltipOverride={'Link'}
        onClick={() => redirectToTravelLink(longLink)}
      />
    </>
  )
}

export const ShortLink = ({
  copyTravelLink,
  redirectToTravelLink,
  leadFormOptions,
  campaignOptions,
  productOptions,
  tagOptions,
  shortLink,
  setShortLink,
}) => {
  return (
    <>
      <b>Link para a campanha</b>

      <DefaultForm
        submitLabel='Gerar link'
        callbackSubmit={data => onSubmit(data, setShortLink)}
        inputs={inputs({
          leadFormOptions,
          campaignOptions,
          productOptions,
          tagOptions,
        })}
      />
      {shortLink && (
        <>
          <input
            style={{
              height: '38px',
              borderRadius: '5px',
              border: '1px solid #ccc',
              width: '90%',
              marginRight: '2%',
            }}
            type='text'
            label='Link para o Viajante'
            name={'link'}
            value={shortLink}
          />
          <IconButton
            iconOverride={'fas fa-copy'}
            tooltipOverride={'Copiar'}
            onClick={() => copyTravelLink(shortLink)}
          />
          <IconButton
            iconOverride={'fas fa-external-link-alt'}
            tooltipOverride={'Link'}
            onClick={() => redirectToTravelLink(shortLink)}
          />
        </>
      )}
    </>
  )
}
