import { useEffect, useRef, useState } from 'react'
import {
  Button,
  Col,
  InputGroup,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  TabPane,
} from 'reactstrap'
import Divisor from '~/components/Divisor'
import FormInput from '~/components/FormInput'
import { FormatValueBRL } from '~/functions/FormatValue'
import useAxios from '~/hooks/useAxios'
import useModal from '~/hooks/useModal'
import useSalesReleasesForm from './hooks/useSalesReleasesForm'
import SalesReleaseFormSelectItem from './components/SalesReleaseFormSelectItem'
import SalesReleasesFormInput from './components/SalesReleasesFormInput'
import {
  SalesReleaseFormComponent,
  SalesReleaseFormContainer,
  SalesReleaseFormValuesTable,
} from './styles'
import SalesReleasesAirForm from './components/SalesReleasesAirForm'
import SalesReleasesHotelForm from './components/SalesReleasesHotelForm'

import SalesReleasesRentalForm from './components/SalesReleasesRentalForm'
import SalesReleasesOtherServicesForm from './components/SalesReleasesOtherServicesForm'
import SalesReleasesTransferForm from './components/SalesReleasesTransferForm'
import SalesReleasesTravelPackageForm from './components/SalesReleasesTravelPackageForm'
import SalesReleasesCustomerForm from './components/SalesReleasesCustomerForm'
import { toast } from 'react-toastify'
import ItemDetails from '~/pages/SalesReleasesGeneralData/Details'
import {
  getCashDfMpCalc,
  getFeeCalc,
  getTableValuesSum,
  getValueCalc,
} from './functions'
import { DEFAULT_TABLE_VALUES_SUM } from './constants'
import { getDateFormated, getMoment } from '~/functions/Date'

export default function SaleReleaseForm() {
  const { apiClient } = useAxios()
  const { showModal, hideModal } = useModal()
  const [activeTabExternal, setActiveTabExternal] = useState('1')
  const [activeTabRoadMap, setActiveTabRoadMap] = useState('1')
  const [lockRoadMap, setLockRoadmap] = useState(false)
  const [showAddRoadmapButton, setShowAddRoadmapButton] = useState(false)
  const [loading, setLoading] = useState(false)
  const { register, setValue, handleSubmit, reset, getValues, watch } =
    useSalesReleasesForm()
  const [roadmap, setRoadmap] = useState([])
  const [newClient, setNewClient] = useState([])
  const lastOpenedModalData = useRef()
  const genDataTypesList = useRef()
  const [productTemplate, setProductTemplate] = useState()
  const customerSupplierWintour = useRef([false, false])
  const [tableValuesSum, setTableValuesSum] = useState(DEFAULT_TABLE_VALUES_SUM)

  const [selectOptionsData, setSelectOptionsData] = useState([])

  useEffect(() => {
    const subs = watch(() => setTableValuesSum(getTableValuesSum(getValues())))

    return () => subs.unsubscribe()
  }, [watch])

  useEffect(async function () {
    let _optData = {}
    let result

    result = await apiClient.options(
      `${process.env.REACT_APP_ENDPOINT_APIS}/salesrelease/generaldatatypes`,
    )

    genDataTypesList.current = result.data

    if (result.data.filter(it => it.id === 22).length > 0) {
      customerSupplierWintour.current[0] = true
    }

    if (result.data.filter(it => it.id === 23).length > 0) {
      customerSupplierWintour.current[1] = true
    }

    result = await apiClient.options('/salesrelease/airports')

    if (result.status === 200) _optData.airports = result.data

    result = await apiClient.options('/salesrelease/cities')
    if (result.status === 200) _optData.cities = result.data

    result = await apiClient.options('/salesrelease/suppliers')
    if (result.status === 200) _optData.suppliers = result.data

    result = await apiClient.options(
      '/salesrelease/generaldata?type=19&activeOnly=true',
    )
    if (result.status === 200) _optData.paymentTypes = result.data

    setSelectOptionsData(_optData)
  }, [])

  async function showInfo(message) {
    toast.info(message)
  }

  async function onSubmit(data) {
    try {
      let values = getValues()

      if (!values.date) {
        return showInfo('Preencha o campo Data de Lançamento')
      }

      if (!values.productCode) {
        return showInfo('Preencha o campo Código Produto')
      }

      if (!values.formOfPayment) {
        return showInfo('Preencha o campo Forma de Pagamento')
      }

      if (!values.serviceProviderName) {
        return showInfo('Preencha o campo Prestador de Serviços')
      }

      if (!values.clientCode && !values.clientCode_subt) {
        return showInfo('Preencha o campo Cliente')
      }

      if (!values.pax) {
        return showInfo('Preencha o campo Passageiro')
      }

      if (!values.provider) {
        return showInfo('Preencha o campo Fornecedor')
      }

      if (!values.issuer) {
        return showInfo('Preencha o campo Emissor')
      }

      setLoading(true)

      let objSubmit = {}

      objSubmit.bilhete = {}

      objSubmit.bilhete.moeda = 'BRL'
      objSubmit.bilhete.dtInternaCadastro = getDateFormated(
        getMoment(),
        'DD/MM/YYYY',
      )

      // group 1
      if (values.saleNumber) {
        objSubmit.bilhete.idvExterno = values.saleNumber ?? 0
      }

      if (values.filial) {
        objSubmit.bilhete.idPostoAtendimento = values.filial ?? 0
      }

      objSubmit.bilhete.postoAtendimento = values.sc
      objSubmit.bilhete.dataLancamento = getDateFormated(
        getMoment(values.date),
        'DD/MM/YYYY',
      )
      objSubmit.bilhete.codigoProduto = values.productCode
      objSubmit.bilhete.formaDePagamento = values.formOfPayment
      objSubmit.bilhete.tipoDomestInter = values.typeOfDestiny
      objSubmit.bilhete.tipoEmissao = values.emissionType?.value ?? undefined
      objSubmit.bilhete.status = values.status

      objSubmit.bilhete.prestadorsvc = values.serviceProviderName
      if (values.formDoc && values.numberDoc) {
        objSubmit.bilhete.numBilhete = values.formDoc + values.numberDoc
      }
      objSubmit.bilhete.localizador = values.codConfirm
      objSubmit.bilhete.canalCaptacao = values.clientCaptationClient
      objSubmit.bilhete.canalVenda = values.salesChannel
      objSubmit.bilhete.cCustosAgencia = values.cca

      // group 2
      objSubmit.bilhete.ctaDuRav = values.duRav4
      objSubmit.bilhete.codOutrasTxs = values.outrasTaxas1Coluna4
      objSubmit.bilhete.codOutrasTxs2 = values.outrasTaxas2Coluna4
      objSubmit.bilhete.codOutrasTxs3 = values.outrasTaxas3Coluna4
      objSubmit.bilhete.contaTaxasAdicionais = values.fee1Coluna4
      objSubmit.bilhete.contaTaxasAdicionais2 = values.fee2Coluna4

      objSubmit.bilhete.valores = {}

      objSubmit.bilhete.valores.item = []

      const tarifa = getCashDfMpCalc(
        'tarifa',
        values.tarifa1,
        values.tarifa2,
        values.tarifa3,
      )

      const taxaEmbarque = getCashDfMpCalc(
        'taxa',
        values.taxaEmbarque1,
        values.taxaEmbarque2,
        values.taxaEmbarque3,
      )

      const duRav = getCashDfMpCalc(
        'taxa_du',
        values.duRav1,
        values.duRav2,
        values.duRav3,
      )

      const outrasTaxas = getCashDfMpCalc(
        'outras_txs',
        values.outrasTaxas1Coluna1,
        values.outrasTaxas1Coluna2,
        values.outrasTaxas1Coluna3,
      )

      const outrasTaxas2 = getCashDfMpCalc(
        'outras_txs2',
        values.outrasTaxas2Coluna1,
        values.outrasTaxas2Coluna2,
        values.outrasTaxas2Coluna3,
      )

      const outrasTaxas3 = getCashDfMpCalc(
        'outras_txs3',
        values.outrasTaxas3Coluna1,
        values.outrasTaxas3Coluna2,
        values.outrasTaxas3Coluna3,
      )

      const fee1 = getFeeCalc('fee', values.fee1Coluna1, values.fee1Coluna3)

      const fee2 = getFeeCalc('fee2', values.fee2Coluna1, values.fee2Coluna3)

      const markup = getCashDfMpCalc(
        'markup',
        values.markup1,
        values.markup2,
        values.markup3,
      )

      const comissionAg = getValueCalc('comissao_ag', values.comissionAg)

      const overAg = getValueCalc('over_ag', values.overAg)

      const taxCardVirtual = getValueCalc(
        'tx_cartao_virtual',
        values.taxCardVirtual,
      )

      const emissionTax = getValueCalc('tx_emissao', values.emissionTax)

      const fullFare = getValueCalc('full_fare', values.fullFare)

      const bestFare = getValueCalc('best_fare', values.bestFare)

      const bestFareDisp = getValueCalc('best_fare_disp', values.bestFareDisp)

      if (tarifa) {
        objSubmit.bilhete.valores.item.push(tarifa)
      }

      if (taxaEmbarque) {
        objSubmit.bilhete.valores.item.push(taxaEmbarque)
      }

      if (duRav) {
        objSubmit.bilhete.valores.item.push(duRav)
      }

      if (outrasTaxas) {
        objSubmit.bilhete.valores.item.push(outrasTaxas)
      }

      if (outrasTaxas2) {
        objSubmit.bilhete.valores.item.push(outrasTaxas2)
      }

      if (outrasTaxas3) {
        objSubmit.bilhete.valores.item.push(outrasTaxas3)
      }

      if (fee1) {
        objSubmit.bilhete.valores.item.push(fee1)
      }

      if (fee2) {
        objSubmit.bilhete.valores.item.push(fee2)
      }

      if (markup) {
        objSubmit.bilhete.valores.item.push(markup)
      }

      if (comissionAg) {
        objSubmit.bilhete.valores.item.push(comissionAg)
      }

      if (overAg) {
        objSubmit.bilhete.valores.item.push(overAg)
      }

      if (taxCardVirtual) {
        objSubmit.bilhete.valores.item.push(taxCardVirtual)
      }

      if (emissionTax) {
        objSubmit.bilhete.valores.item.push(emissionTax)
      }

      if (fullFare) {
        objSubmit.bilhete.valores.item.push(fullFare)
      }

      if (bestFare) {
        objSubmit.bilhete.valores.item.push(bestFare)
      }

      if (bestFareDisp) {
        objSubmit.bilhete.valores.item.push(bestFareDisp)
      }

      objSubmit.bilhete.vencimentos = {}

      objSubmit.bilhete.vencimentos.item = []

      // group 3
      objSubmit.bilhete.cliente = values.clientCode.replace(/[.\-/]+/g, '')
      if (values.customerDiscount) {
        objSubmit.bilhete.descontoCliente = values.customerDiscount ?? 0
      }
      if (values.customerDueDate) {
        objSubmit.bilhete.vencimentos.item.push({
          codigo: 'cliente',
          valor: getDateFormated(
            getMoment(values.customerDueDate),
            'DD/MM/YYYY',
          ),
        })
      }
      objSubmit.bilhete.passageiro = values.pax
      objSubmit.bilhete.fornecedor = values.provider
      if (values.netFare?.value) {
        objSubmit.bilhete.tarifaNet = values.netFare?.value ?? 0
      }
      objSubmit.bilhete.ctaTxEmissao = values.codEmissionTax
      objSubmit.bilhete.cartaoCp = values.ownCardCode
      if (values.ownCardDue) {
        objSubmit.bilhete.vencimentos.item.push({
          codigo: 'cartao_cp',
          valor: getDateFormated(getMoment(values.ownCardDue), 'DD/MM/YYYY'),
        })
      }

      // group 4
      objSubmit.bilhete.cCustosCliente = values.centerOfClientCosts
      objSubmit.bilhete.contrato = values.contract
      objSubmit.bilhete.matricula = values.registry
      objSubmit.bilhete.numeroRequisicao = values.requisitionNumber
      if (values.requisitionDate) {
        objSubmit.bilhete.dataRequisicao = getDateFormated(
          getMoment(values.requisitionDate),
          'DD/MM/YYYY',
        )
      }
      objSubmit.bilhete.solicitante = values.requester
      objSubmit.bilhete.aprovador = values.approver

      objSubmit.bilhete.departamento = values.department
      objSubmit.bilhete.projeto = values.project
      objSubmit.bilhete.motivoViagem = values.tripReason
      objSubmit.bilhete.motivoRecusa = values.tripPoliticReason

      objSubmit.bilhete.emissor = values.issuer
      objSubmit.bilhete.promotor = values.promoter
      objSubmit.bilhete.gerente = values.manager

      // group 5
      objSubmit.bilhete.infoAdicionaisValue = values.externalInfo
      objSubmit.bilhete.infoInternasValue = values.internalInfo

      let itemRoadmap = {}

      objSubmit.bilhete.tipoRoteiro = productTemplate.value

      if (newClient.cpfCnpj) {
        objSubmit.bilhete.dadosCliente = {}
        objSubmit.bilhete.dadosCliente.acaoCli = 'IU'
        objSubmit.bilhete.dadosCliente.cpfCnpj = objSubmit.bilhete.cliente
        objSubmit.bilhete.dadosCliente.razaoSocial = newClient.fullName
        objSubmit.bilhete.dadosCliente.tipoFj =
          objSubmit.bilhete.cliente.length == 11 ? 'F' : 'J'
        objSubmit.bilhete.dadosCliente.email = newClient.email
        objSubmit.bilhete.dadosCliente.tel = newClient.phone
      }

      objSubmit.bilhete.roteiro = {}
      // 1 = Aéreo
      // 2 = Hotel
      // 3 = Locação
      // 4 = Outros Serviços (sem Máscara)
      // 5 = Transfer Hotel/Aeroporto/Hotel
      // 6 = Pacotes
      // 7 = Outros Serviços

      if (!values.roadmap) {
        toast.info('Preencha o roteiro da venda.')

        return setLoading(false)
      }

      //Aéreo
      if (objSubmit.bilhete.tipoRoteiro === 1) {
        objSubmit.bilhete.roteiro.aereo = {}
        objSubmit.bilhete.roteiro.aereo.trecho = []

        if (values.roadmap)
          objSubmit.bilhete.roteiro.aereo.trecho = roadmap.map(item => ({
            ciaiata: item.cia?.value,
            aeroportoorigem: item.origin?.value,
            aeroportodestino: item.destiny?.value,
            NumeroVoo: item.flight,
            DataPartida: item.departureDate,
            HoraPartida: item.departureTime,
            DataChegada: item.arrivalDate,
            HoraChegada: item.arrivalTime,
            Classe: item.classDesc,
            BaseTarifaria: item.fareBase,
            TicketDesignator: item.tktDesignator,
          }))
      }

      //Hotel
      if (objSubmit.bilhete.tipoRoteiro === 2) {
        objSubmit.bilhete.roteiro.hotel = {}

        if (values.roadmap) {
          itemRoadmap = roadmap[0]
          objSubmit.bilhete.roteiro.hotel.nrApts = itemRoadmap.apts
          objSubmit.bilhete.roteiro.hotel.CategApt = itemRoadmap.categoryApts
          objSubmit.bilhete.roteiro.hotel.TipoApt = itemRoadmap.typeApts
          objSubmit.bilhete.roteiro.hotel.DtCheckIn = itemRoadmap.checkIn
          objSubmit.bilhete.roteiro.hotel.DtCheckOut = itemRoadmap.checkOut
          objSubmit.bilhete.roteiro.hotel.NrHospedes = itemRoadmap.paxCount
          objSubmit.bilhete.roteiro.hotel.RegAlimentacao =
            itemRoadmap.feedRegister
          objSubmit.bilhete.roteiro.hotel.CodTipoPagto =
            itemRoadmap.paymentType?.value
          objSubmit.bilhete.roteiro.hotel.DtConfirmacao =
            itemRoadmap.confirmationDate && itemRoadmap !== ''
              ? itemRoadmap.confirmationDate
              : getDateFormated(getMoment(), 'YYYY-MM-DD')
          objSubmit.bilhete.roteiro.hotel.ConfirmadoPor =
            itemRoadmap.confirmedBy
        }
      }

      //Locação
      if (objSubmit.bilhete.tipoRoteiro === 3) {
        objSubmit.bilhete.roteiro.locacao = {}

        if (values.roadmap) {
          itemRoadmap = roadmap[0]
          let roadmapSubmit = objSubmit.bilhete.roteiro.locacao
          roadmapSubmit.cidadeRetirada = itemRoadmap.withdrawalCity?.value
          roadmapSubmit.localRetirada = itemRoadmap.withdrawalLocation
          roadmapSubmit.dtRetirada = itemRoadmap.withdrawalDate
          roadmapSubmit.hrRetirada = itemRoadmap.withdrawalTime
          roadmapSubmit.localDevolucao = itemRoadmap.returnLocation
          roadmapSubmit.dtDevolucao = itemRoadmap.returnDate
          roadmapSubmit.hrDevolucao = itemRoadmap.returnTime
          roadmapSubmit.categVeiculo = itemRoadmap.vehicleCategory
          roadmapSubmit.codTipoPagto = itemRoadmap.paymentType?.value
          roadmapSubmit.dtConfirmacao = itemRoadmap.confirmationDate
          roadmapSubmit.confirmadoPor = itemRoadmap.confirmedBy
        }
      }

      //Transfer Hotel/Aeroporto/Hotel
      if (objSubmit.bilhete.tipoRoteiro === 5) {
        objSubmit.bilhete.roteiro.transfer = {}

        if (values.roadmap) {
          itemRoadmap = roadmap[0]
          let roadmapSubmit = objSubmit.bilhete.roteiro.transfer
          roadmapSubmit.transferIn = {}
          roadmapSubmit.transferOut = {}
          roadmapSubmit.transferIn.hotelTransferIn =
            itemRoadmap.arrivalHotel?.value
          roadmapSubmit.transferIn.ciaIataChegada = itemRoadmap.company?.value
          roadmapSubmit.transferIn.numeroVooChegada = itemRoadmap.flightNumber
          roadmapSubmit.transferIn.dataChegadaVoo = itemRoadmap.arrivalDate
          roadmapSubmit.transferIn.horaChegadaVoo = itemRoadmap.arrivalTime
          roadmapSubmit.transferIn.aeroportoChegada =
            itemRoadmap.arrivalAirport?.value
          roadmapSubmit.transferOut.hotelTransferOut =
            itemRoadmap.departureHotel?.value
          roadmapSubmit.transferOut.dataApanharPax = itemRoadmap.checkoutDate
          roadmapSubmit.transferOut.horaApanharPax = itemRoadmap.checkoutTime
          roadmapSubmit.transferOut.ciaIataPartida =
            itemRoadmap.flightDeparturecompany?.value
          roadmapSubmit.transferOut.numeroVooPartida =
            itemRoadmap.flightDepartureNumber
          roadmapSubmit.transferOut.dataPartidaVoo =
            itemRoadmap.flightDepartureDate
          roadmapSubmit.transferOut.horaPartidaVoo =
            itemRoadmap.flightDepartureTime
          roadmapSubmit.transferOut.aeroportoPartida =
            itemRoadmap.flightDepartureAirport?.value
        }
      }

      //Pacote
      if (objSubmit.bilhete.tipoRoteiro === 6) {
        objSubmit.bilhete.roteiro.pacote = {}

        if (values.roadmap) {
          itemRoadmap = roadmap[0]
          let roadmapSubmit = objSubmit.bilhete.roteiro.pacote
          roadmapSubmit.cidDestPrincipal =
            itemRoadmap.packageWithdrawalCity?.value
          roadmapSubmit.dataInicioPacote = itemRoadmap.dtStartService
          roadmapSubmit.dataFimPacote = itemRoadmap.dtEndService
          roadmapSubmit.descricaoPacote = itemRoadmap.otherServicesDescription
        }
      }

      //Outros Serviços
      if (objSubmit.bilhete.tipoRoteiro === 7) {
        objSubmit.bilhete.roteiro.outrosServicos = {}

        if (values.roadmap) {
          itemRoadmap = roadmap[0]
          let roadmapSubmit = objSubmit.bilhete.roteiro.outrosServicos
          roadmapSubmit.cidDestPrincipal = itemRoadmap.withdrawalCity?.value
          roadmapSubmit.dataInicioOutrosSvcs = itemRoadmap.dtStartService
          roadmapSubmit.dataFimOutrosSvcs = itemRoadmap.dtEndService
          roadmapSubmit.descricaoOutrosSvcs =
            itemRoadmap.otherServicesDescription
        }
      }

      //Outros Serviços (Sem máscara)
      if (objSubmit.bilhete.tipoRoteiro === 4) {
        objSubmit.bilhete.roteiro.outros = { roteiroTexto: {} }

        if (values.roadmap) {
          let roadmapSubmit = objSubmit.bilhete.roteiro.outros.roteiroTexto
          roadmapSubmit.descricao = values.roadmap
        }
      }

      console.log(objSubmit)

      const response = await apiClient.post(
        '/integrationsales/makesale',
        objSubmit,
      )

      await showSuccessModal(response?.data)

      setLoading(false)
    } catch (error) {
      setLoading(false)

      return await showErrorModal()
    }
  }

  async function showErrorModal() {
    showModal({
      title: 'Erro',
      body: () => {
        return (
          <div>
            Ocorreu um problema ao enviar o Lançamento, contate o suporte, ou
            tente mais tarde!
          </div>
        )
      },
    })
  }

  async function showSuccessModal(protocolNumber) {
    showModal({
      title: 'Lançamento realizado',
      body: () => {
        return (
          <>
            <h3>O lançamento foi efetuado com sucesso.</h3>
            <br />
            <div>
              O número do protocolo gerado é: <b>{protocolNumber}</b>
            </div>
            <div>
              Para efetuar um novo lançamento,
              <a href='/sales-releases/create'> clique aqui</a>.
            </div>
            <div>
              Para retornar para a lista de protocolos,
              <a href='/sales-releases'> clique aqui</a>.
            </div>
          </>
        )
      },
    })
  }

  async function showDetailsGenericItem(e) {
    if (!e) return

    showModal({
      title: 'Cadastrar',
      body: () => (
        <ItemDetails
          typeDsc={e.description}
          typeId={e.id}
          reloadListCallback={() =>
            showGenericModal(
              lastOpenedModalData.current.modalTitle,
              lastOpenedModalData.current.modalDesc,
              lastOpenedModalData.current.endPointOrData,
              lastOpenedModalData.current.setValueField,
              lastOpenedModalData.current.typeId,
            )
          }
        />
      ),
    })
  }

  async function showGenericModal(
    modalTitle,
    modalDesc,
    endPointOrData,
    setValueField,
    typeId,
  ) {
    try {
      setLoading(true)

      if (setValueField === 'productCode') {
        setValue('roadmap', '')
        setRoadmap([])
      }

      let res = {}
      let generalDataType = null
      let showDetailsGenericItemHandle = null

      if (typeof endPointOrData === 'string') {
        res = await apiClient.options(endPointOrData)
      } else {
        res.data = endPointOrData
      }

      if (typeId) {
        generalDataType = genDataTypesList.current.filter(
          it => it.id === typeId,
        )
        showDetailsGenericItemHandle = () =>
          showDetailsGenericItem(generalDataType[0])
      }

      lastOpenedModalData.current = {
        modalTitle,
        modalDesc,
        endPointOrData,
        setValueField,
        typeId,
      }

      showModal({
        title: modalTitle,
        desc: modalDesc,
        body: () => (
          <SalesReleaseFormSelectItem
            items={res.data}
            newRegisterItemModel={showDetailsGenericItemHandle}
            renderItem={item => {
              return (
                <>
                  <span>
                    {item.value} - {item.label}
                  </span>
                </>
              )
            }}
            callback={async data => {
              if (data[0]) {
                setValue(setValueField, data[0].value)
                setValue(setValueField + '_subt', data[0])
                if (setValueField === 'productCode') {
                  let response = await apiClient.options(
                    `${process.env.REACT_APP_ENDPOINT_APIS}/salesrelease/generaldata?type=${typeId}&code=${data[0].value}&activeOnly=true`,
                  )

                  let additionalInfoObj = null
                  if (response.status === 200) {
                    data = response.data[0]
                    additionalInfoObj = JSON.parse(data.additionalInfo)
                    setProductTemplate(
                      additionalInfoObj?.template ?? { label: '', value: 4 },
                    )
                  }

                  if (
                    additionalInfoObj?.template?.value &&
                    additionalInfoObj?.template?.value !== 4
                  ) {
                    setValue('roadmap', '')
                    setLockRoadmap(true)
                    setShowAddRoadmapButton(true)
                  } else {
                    setLockRoadmap(false)
                    setShowAddRoadmapButton(false)
                  }
                }
              }

              hideModal()
            }}
            max={1}
          />
        ),
      })

      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  async function showAddRoadmapModal() {
    setLockRoadmap(true)

    const productCode = getValues().productCode
    let modalTypeList = {
      t1: SalesReleasesAirForm,
      t2: SalesReleasesHotelForm,
      t3: SalesReleasesRentalForm,
      t7: SalesReleasesOtherServicesForm,
      t5: SalesReleasesTransferForm,
      t6: SalesReleasesTravelPackageForm,
      t4: '', //sem template
    }

    let ModalType = modalTypeList['t' + productTemplate.value]

    if (ModalType) {
      showModal({
        title: productCode,
        desc: 'Adicionar roteiro',
        body: () => {
          return (
            <ModalType
              internalOptionData={selectOptionsData}
              onChange={(data, formattedData) => {
                setLockRoadmap(true)
                setRoadmap(data)
                setValue('roadmap', formattedData)
              }}
              defaultValue={roadmap}
            />
          )
        },
      })
    } else {
      setLockRoadmap(false)
    }
  }

  async function showCustomerModal() {
    showModal({
      title: 'Cliente',
      desc: null,
      body: () => (
        <SalesReleasesCustomerForm
          callback={data => {
            if (data) {
              setValue('clientCode', data.value ?? data.cpfCnpj)

              setValue('clientCode' + '_subt', data)

              setNewClient(data)
            }

            hideModal()
          }}
        />
      ),
    })
  }

  return (
    <SalesReleaseFormContainer>
      <SalesReleaseFormComponent onSubmit={handleSubmit(onSubmit)}>
        <fieldset disabled={loading}>
          <Row>
            <Col sm={12}>
              <label>
                <b style={{ fontWeight: '600' }}>* Campos obrigatórios</b>
              </label>
            </Col>
          </Row>

          <Row>
            <SalesReleasesFormInput
              register={register}
              setValue={setValue}
              name={'saleNumber'}
              label={'Venda N°'}
              type={'number'}
              lg={1}
              xl={1}
            />

            <SalesReleasesFormInput
              register={register}
              setValue={setValue}
              name={'sc'}
              label={'SC'}
              lg={1}
              xl={1}
            />

            <SalesReleasesFormInput
              register={register}
              setValue={setValue}
              name={'filial'}
              label={'Filial'}
              type={'number'}
              lg={1}
              xl={1}
            />

            <SalesReleasesFormInput
              register={register}
              setValue={setValue}
              name={'date'}
              label={'Data'}
              type={'date'}
              required
              lg={1}
              xl={1}
            />

            <SalesReleasesFormInput
              register={register}
              setValue={setValue}
              getValues={getValues}
              name={'productCode'}
              label={'Código produto'}
              lg={2}
              xl={2}
              actionRightButton={() =>
                showGenericModal(
                  'Código produto',
                  'Selecione o código do produto',
                  '/salesrelease/generaldata?type=1&activeOnly=true',
                  'productCode',
                  1,
                )
              }
              disabled
              required
            />

            <SalesReleasesFormInput
              register={register}
              setValue={setValue}
              getValues={getValues}
              name={'formOfPayment'}
              label={'Forma pagamento'}
              lg={2}
              xl={2}
              actionRightButton={() =>
                showGenericModal(
                  'Forma de Pagamento',
                  'Selecione a forma de pagamento',
                  '/salesrelease/generaldata?type=2&activeOnly=true',
                  'formOfPayment',
                  2,
                )
              }
              disabled
              required
            />

            <SalesReleasesFormInput
              register={register}
              setValue={setValue}
              name={'typeOfDestiny'}
              label={'Tipo destino'}
              lg={1}
              xl={1}
              radioOptions={[
                { label: 'Doméstico', value: 'D' },
                { label: 'Internacional', value: 'I' },
              ]}
            />

            <SalesReleasesFormInput
              register={register}
              setValue={setValue}
              name={'emissionType'}
              label={'Tipo emissão'}
              lg={1}
              xl={1}
              selectOptions={[
                { label: 'Emissão', value: 'E' },
                { label: 'Remarcação', value: 'R' },
                { label: 'Substituição', value: 'S' },
              ]}
            />

            <SalesReleasesFormInput
              register={register}
              setValue={setValue}
              getValues={getValues}
              name={'status'}
              label={'Status'}
              lg={2}
              xl={2}
              disabled
              actionRightButton={() =>
                showGenericModal(
                  'Status',
                  'Selecione o Status',
                  '/salesrelease/generaldata?type=3&activeOnly=true',
                  'status',
                  3,
                )
              }
            />
          </Row>

          <Row>
            <SalesReleasesFormInput
              register={register}
              setValue={setValue}
              getValues={getValues}
              name={'serviceProviderName'}
              label={'Prestador serviço'}
              sm={12}
              md={6}
              lg={2}
              xl={2}
              actionRightButton={() =>
                showGenericModal(
                  'Prestador de Serviço',
                  'Selecione o prestador de serviço',
                  customerSupplierWintour.current[1]
                    ? '/salesrelease/generaldata?type=23&activeOnly=true'
                    : selectOptionsData.suppliers,
                  'serviceProviderName',
                  customerSupplierWintour.current[1] ? 23 : null,
                )
              }
              disabled
              required
            />

            <SalesReleasesFormInput
              register={register}
              setValue={setValue}
              name={'formDoc'}
              label={'Form'}
              sm={12}
              md={6}
              lg={1}
              xl={1}
            />

            <SalesReleasesFormInput
              register={register}
              setValue={setValue}
              name={'numberDoc'}
              label={'Número doc.'}
              sm={12}
              md={6}
              lg={1}
              xl={1}
            />

            <SalesReleasesFormInput
              register={register}
              setValue={setValue}
              name={'codConfirm'}
              label={'Loc./Cód./Confirmação'}
              required
              sm={12}
              md={6}
              lg={2}
              xl={2}
            />

            <SalesReleasesFormInput
              register={register}
              setValue={setValue}
              name={'clientCaptationClient'}
              label={'Canal captação'}
              sm={12}
              md={6}
              lg={2}
              xl={2}
            />

            <SalesReleasesFormInput
              register={register}
              setValue={setValue}
              name={'salesChannel'}
              label={'Canal venda'}
              sm={12}
              md={6}
              lg={2}
              xl={2}
            />

            <SalesReleasesFormInput
              register={register}
              setValue={setValue}
              name={'cca'}
              label={'CCA'}
              required
              sm={12}
              md={6}
              lg={2}
              xl={2}
            />
          </Row>

          <Row>
            <Col sm={12} md={12} lg={5} xl={5}>
              <SalesReleaseFormValuesTable
                bordered
                hover
                responsive
                striped
                size='sm'
              >
                <thead>
                  <tr>
                    <th>Valores em R$</th>
                    <th>À Vista/Faturada</th>
                    <th>Cartão/ch. Fornecedor</th>
                    <th>Cartão (MP) Agência</th>
                    <th>Código de contas</th>
                    <th>Totais</th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td>Tarifa</td>
                    <td>
                      <FormInput
                        register={register}
                        name='tarifa1'
                        type={'number'}
                        step={'.01'}
                      />
                    </td>
                    <td>
                      <FormInput
                        register={register}
                        name='tarifa2'
                        type={'number'}
                        step={'.01'}
                      />
                    </td>
                    <td>
                      <FormInput
                        register={register}
                        name='tarifa3'
                        type={'number'}
                        step={'.01'}
                      />
                    </td>
                    <td>&nbsp;</td>
                    <td>{FormatValueBRL(tableValuesSum.fareSum)}</td>
                  </tr>

                  <tr>
                    <td>Tx. Embarq.</td>
                    <td>
                      <FormInput
                        register={register}
                        name='taxaEmbarque1'
                        type={'number'}
                        step={'.01'}
                      />
                    </td>
                    <td>
                      <FormInput
                        register={register}
                        name='taxaEmbarque2'
                        type={'number'}
                        step={'.01'}
                      />
                    </td>
                    <td>
                      <FormInput
                        register={register}
                        name='taxaEmbarque3'
                        type={'number'}
                        step={'.01'}
                      />
                    </td>
                    <td>&nbsp;</td>
                    <td>{FormatValueBRL(tableValuesSum.checkInTaxSum)}</td>
                  </tr>

                  <tr>
                    <td>DU/RAV</td>
                    <td>
                      <FormInput
                        register={register}
                        name='duRav1'
                        type={'number'}
                        step={'.01'}
                      />
                    </td>
                    <td>
                      <FormInput
                        register={register}
                        name='duRav2'
                        type={'number'}
                        step={'.01'}
                      />
                    </td>
                    <td>
                      <FormInput
                        register={register}
                        name='duRav3'
                        type={'number'}
                        step={'.01'}
                      />
                    </td>
                    <td>
                      <InputGroup style={{ width: '100%' }}>
                        <SalesReleasesFormInput
                          register={register}
                          setValue={setValue}
                          name={'duRav4'}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          disabled
                          Wrapper={'div'}
                          actionRightButton={() =>
                            showGenericModal(
                              'DU/RAV',
                              'Selecionar DU/RAV',
                              [
                                { label: 'TX-DU', value: 'TX-DU' },
                                { label: 'RAV-AG', value: 'RAV-AG' },
                              ],
                              'duRav4',
                            )
                          }
                        />
                      </InputGroup>
                    </td>
                    <td>{FormatValueBRL(tableValuesSum.duRavSum)}</td>
                  </tr>

                  <tr>
                    <td>Outras Txs</td>
                    <td>
                      <FormInput
                        register={register}
                        name='outrasTaxas1Coluna1'
                        type={'number'}
                        step={'.01'}
                      />
                      <FormInput
                        register={register}
                        name='outrasTaxas2Coluna1'
                        type={'number'}
                        step={'.01'}
                      />
                      <FormInput
                        register={register}
                        name='outrasTaxas3Coluna1'
                        type={'number'}
                        step={'.01'}
                      />
                    </td>
                    <td>
                      <FormInput
                        register={register}
                        name='outrasTaxas1Coluna2'
                        type={'number'}
                        step={'.01'}
                      />
                      <FormInput
                        register={register}
                        name='outrasTaxas2Coluna2'
                        type={'number'}
                        step={'.01'}
                      />
                      <FormInput
                        register={register}
                        name='outrasTaxas3Coluna2'
                        type={'number'}
                        step={'.01'}
                      />
                    </td>
                    <td>
                      <FormInput
                        register={register}
                        name='outrasTaxas1Coluna3'
                        type={'number'}
                        step={'.01'}
                      />
                      <FormInput
                        register={register}
                        name='outrasTaxas2Coluna3'
                        type={'number'}
                        step={'.01'}
                      />
                      <FormInput
                        register={register}
                        name='outrasTaxas3Coluna3'
                        type={'number'}
                        step={'.01'}
                      />
                    </td>
                    <td>
                      <InputGroup>
                        <SalesReleasesFormInput
                          register={register}
                          setValue={setValue}
                          name={'outrasTaxas1Coluna4'}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          disabled
                          Wrapper={'div'}
                          actionRightButton={() =>
                            showGenericModal(
                              'Outras taxas',
                              'Selecionar taxa',
                              '/salesrelease/generaldata?type=20&activeOnly=true',
                              'outrasTaxas1Coluna4',
                              20,
                            )
                          }
                        />
                      </InputGroup>

                      <InputGroup>
                        <SalesReleasesFormInput
                          register={register}
                          setValue={setValue}
                          name={'outrasTaxas2Coluna4'}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          disabled
                          Wrapper={'div'}
                          actionRightButton={() =>
                            showGenericModal(
                              'Outras taxas',
                              'Selecionar taxa',
                              '/salesrelease/generaldata?type=20&activeOnly=true',
                              'outrasTaxas2Coluna4',
                              20,
                            )
                          }
                        />
                      </InputGroup>

                      <InputGroup>
                        <SalesReleasesFormInput
                          register={register}
                          setValue={setValue}
                          name={'outrasTaxas3Coluna4'}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          disabled
                          Wrapper={'div'}
                          actionRightButton={() =>
                            showGenericModal(
                              'Outras taxas',
                              'Selecionar taxa',
                              '/salesrelease/generaldata?type=20&activeOnly=true',
                              'outrasTaxas3Coluna4',
                              20,
                            )
                          }
                        />
                      </InputGroup>
                    </td>
                    <td>{FormatValueBRL(tableValuesSum.otherTaxSum)}</td>
                  </tr>

                  <tr>
                    <td>Fee</td>
                    <td>
                      <FormInput
                        register={register}
                        name='fee1Coluna1'
                        type={'number'}
                        step={'.01'}
                      />
                      <FormInput
                        register={register}
                        name='fee2Coluna1'
                        type={'number'}
                        step={'.01'}
                      />
                    </td>
                    <td>&nbsp;</td>
                    <td>
                      <FormInput
                        register={register}
                        name='fee1Coluna3'
                        type={'number'}
                        step={'.01'}
                      />
                      <FormInput
                        register={register}
                        name='fee2Coluna3'
                        type={'number'}
                        step={'.01'}
                      />
                    </td>
                    <td>
                      <InputGroup>
                        <SalesReleasesFormInput
                          register={register}
                          setValue={setValue}
                          name={'fee1Coluna4'}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          disabled
                          Wrapper={'div'}
                          actionRightButton={() =>
                            showGenericModal(
                              'Fee',
                              'Selecionar fee',
                              '/salesrelease/generaldata?type=21&activeOnly=true',
                              'fee1Coluna4',
                              21,
                            )
                          }
                        />
                      </InputGroup>
                      <InputGroup>
                        <SalesReleasesFormInput
                          register={register}
                          setValue={setValue}
                          name={'fee2Coluna4'}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          disabled
                          Wrapper={'div'}
                          actionRightButton={() =>
                            showGenericModal(
                              'Fee',
                              'Selecionar fee',
                              '/salesrelease/generaldata?type=21&activeOnly=true',
                              'fee2Coluna4',
                              21,
                            )
                          }
                        />
                      </InputGroup>
                    </td>
                    <td>{FormatValueBRL(tableValuesSum.feeSum)}</td>
                  </tr>

                  <tr>
                    <td>Markup</td>
                    <td>
                      <FormInput
                        register={register}
                        name='markup1'
                        type={'number'}
                        step={'.01'}
                      />
                    </td>
                    <td>
                      <FormInput
                        register={register}
                        name='markup2'
                        type={'number'}
                        step={'.01'}
                      />
                    </td>
                    <td>
                      <FormInput
                        register={register}
                        name='markup3'
                        type={'number'}
                        step={'.01'}
                      />
                    </td>
                    <td>&nbsp;</td>
                    <td>{FormatValueBRL(tableValuesSum.markupSum)}</td>
                  </tr>

                  <tr>
                    <td>Totais</td>
                    <td>
                      {FormatValueBRL(tableValuesSum.cashInvoicePaymentTotal)}
                    </td>
                    <td>{FormatValueBRL(tableValuesSum.cardProviderTotal)}</td>
                    <td>{FormatValueBRL(tableValuesSum.agencyCardTotal)}</td>
                    <td>&nbsp;</td>
                    <td>{FormatValueBRL(tableValuesSum.total)}</td>
                  </tr>
                </tbody>
              </SalesReleaseFormValuesTable>
            </Col>

            <Col sm={12} md={12} lg={7} xl={7}>
              <Row>
                <SalesReleasesFormInput
                  register={register}
                  setValue={setValue}
                  getValues={getValues}
                  name={'clientCode'}
                  label={'Cliente'}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  actionRightButton={showCustomerModal}
                  disabled
                  required
                />

                <SalesReleasesFormInput
                  register={register}
                  setValue={setValue}
                  name={'customerDiscount'}
                  label={'Desconto'}
                  type={'number'}
                  step={'.01'}
                  sm={12}
                  md={12}
                  lg={3}
                  xl={3}
                />

                <SalesReleasesFormInput
                  register={register}
                  setValue={setValue}
                  name={'customerDueDate'}
                  label={'Vencim. cliente'}
                  type={'date'}
                  sm={12}
                  md={12}
                  lg={3}
                  xl={3}
                />

                <SalesReleasesFormInput
                  register={register}
                  setValue={setValue}
                  name={'pax'}
                  label={'Pax'}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  required
                />

                <SalesReleasesFormInput
                  register={register}
                  setValue={setValue}
                  getValues={getValues}
                  name={'provider'}
                  label={'Fornecedor'}
                  sm={12}
                  md={12}
                  lg={9}
                  xl={9}
                  actionRightButton={() =>
                    showGenericModal(
                      'Fornecedor',
                      'Selecione o fornecedor',
                      customerSupplierWintour.current[1]
                        ? '/salesrelease/generaldata?type=23&activeOnly=true'
                        : selectOptionsData.suppliers,
                      'provider',
                      customerSupplierWintour.current[1] ? 23 : null,
                    )
                  }
                  disabled
                  required
                />

                <SalesReleasesFormInput
                  register={register}
                  setValue={setValue}
                  name={'netFare'}
                  label={'Tarifa NET'}
                  sm={12}
                  md={12}
                  lg={3}
                  xl={3}
                  selectOptions={[
                    { label: 'Não', value: '0' },
                    { label: 'Sim', value: '1' },
                  ]}
                />

                <SalesReleasesFormInput
                  register={register}
                  setValue={setValue}
                  name={'comissionAg'}
                  label={'Comissão'}
                  type={'number'}
                  step={'.01'}
                  sm={12}
                  md={12}
                  lg={4}
                  xl={4}
                />

                <SalesReleasesFormInput
                  register={register}
                  setValue={setValue}
                  name={'overAg'}
                  label={'Over'}
                  type={'number'}
                  step={'.01'}
                  sm={12}
                  md={12}
                  lg={4}
                  xl={4}
                />

                <SalesReleasesFormInput
                  register={register}
                  setValue={setValue}
                  name={'taxCardVirtual'}
                  label={'Tx. Cartão Virtual'}
                  type={'number'}
                  step={'.01'}
                  sm={12}
                  md={12}
                  lg={4}
                  xl={4}
                />

                <SalesReleasesFormInput
                  register={register}
                  setValue={setValue}
                  name={'emissionTax'}
                  label={'Taxa emissão'}
                  type={'number'}
                  step={'.01'}
                  sm={12}
                  md={12}
                  lg={4}
                  xl={4}
                />

                <SalesReleasesFormInput
                  register={register}
                  setValue={setValue}
                  name={'codEmissionTax'}
                  label={'Conta tx. emissão'}
                  sm={12}
                  md={12}
                  lg={4}
                  xl={4}
                />
              </Row>

              {getValues()?.formOfPayment === 'CP' && (
                <Row>
                  <SalesReleasesFormInput
                    register={register}
                    setValue={setValue}
                    getValues={getValues}
                    name={'ownCardCode'}
                    label={'Cartão próprio'}
                    sm={12}
                    md={12}
                    lg={9}
                    xl={9}
                    actionRightButton={() =>
                      showGenericModal(
                        'Cartão próprio',
                        'Selecione o fornecedor',
                        customerSupplierWintour.current[1]
                          ? '/salesrelease/generaldata?type=23&activeOnly=true'
                          : selectOptionsData.suppliers,
                        'ownCardCode',
                        customerSupplierWintour.current[1] ? 23 : null,
                      )
                    }
                    disabled
                  />

                  <SalesReleasesFormInput
                    register={register}
                    setValue={setValue}
                    name={'ownCardDue'}
                    label={'Vencim. cartão próprio'}
                    type={'date'}
                    sm={12}
                    md={12}
                    lg={3}
                    xl={3}
                  />
                </Row>
              )}
            </Col>
          </Row>

          <Row>
            <SalesReleasesFormInput
              register={register}
              setValue={setValue}
              getValues={getValues}
              name={'centerOfClientCosts'}
              label='Centro de custos do cliente'
              disabled
              sm={12}
              md={12}
              lg={4}
              xl={4}
              actionRightButton={() =>
                showGenericModal(
                  'Centros de custos - cliente',
                  'Selecionar cliente',
                  '/salesrelease/generaldata?type=9&activeOnly=true',
                  'centerOfClientCosts',
                  9,
                )
              }
            />

            <SalesReleasesFormInput
              register={register}
              setValue={setValue}
              getValues={getValues}
              sm={12}
              md={12}
              lg={1}
              xl={1}
              name={'contract'}
              label={'Contrato'}
              disabled
              actionRightButton={() =>
                showGenericModal(
                  'Contratos de Clientes',
                  'Selecionar contrato',
                  '/salesrelease/generaldata?type=10&activeOnly=true',
                  'contract',
                  10,
                )
              }
            />

            <SalesReleasesFormInput
              register={register}
              setValue={setValue}
              getValues={getValues}
              name={'registry'}
              label={'Matrícula'}
              disabled
              sm={12}
              md={12}
              lg={1}
              xl={1}
              actionRightButton={() =>
                showGenericModal(
                  'Matrícula',
                  'Selecionar matrícula',
                  '/salesrelease/generaldata?type=11&activeOnly=true',
                  'registry',
                  11,
                )
              }
            />

            <SalesReleasesFormInput
              register={register}
              setValue={setValue}
              name={'requisitionNumber'}
              label={'N°. Requisição'}
              sm={12}
              md={12}
              lg={1}
              xl={1}
            />

            <SalesReleasesFormInput
              register={register}
              setValue={setValue}
              name={'requisitionDate'}
              label={'Dt. Req.'}
              type={'date'}
              sm={12}
              md={12}
              lg={1}
              xl={1}
            />

            <SalesReleasesFormInput
              register={register}
              setValue={setValue}
              getValues={getValues}
              name={'requester'}
              label={'Solicitante'}
              disabled
              sm={12}
              md={12}
              lg={2}
              xl={2}
              actionRightButton={() =>
                showGenericModal(
                  'Solicitante',
                  'Selecionar solicitante',
                  '/salesrelease/generaldata?type=12&activeOnly=true',
                  'requester',
                  12,
                )
              }
            />

            <SalesReleasesFormInput
              register={register}
              setValue={setValue}
              getValues={getValues}
              name={'approver'}
              label={'Aprovador'}
              disabled
              sm={12}
              md={12}
              lg={2}
              xl={2}
              actionRightButton={() =>
                showGenericModal(
                  'Aprovador',
                  'Selecionar aprovador',
                  '/salesrelease/generaldata?type=13&activeOnly=true',
                  'approver',
                  13,
                )
              }
            />

            <SalesReleasesFormInput
              register={register}
              setValue={setValue}
              getValues={getValues}
              name={'department'}
              label={'Departamento'}
              disabled
              sm={12}
              md={12}
              lg={2}
              xl={2}
              actionRightButton={() =>
                showGenericModal(
                  'Departamento de Clientes',
                  'Selecionar departamento',
                  '/salesrelease/generaldata?type=14&activeOnly=true',
                  'department',
                  14,
                )
              }
            />

            <SalesReleasesFormInput
              register={register}
              setValue={setValue}
              getValues={getValues}
              name={'project'}
              label={'Projeto'}
              disabled
              sm={12}
              md={12}
              lg={2}
              xl={2}
              actionRightButton={() =>
                showGenericModal(
                  'Projeto de Clientes',
                  'Selecionar projeto',
                  '/salesrelease/generaldata?type=15&activeOnly=true',
                  'project',
                  15,
                )
              }
            />

            <SalesReleasesFormInput
              register={register}
              setValue={setValue}
              getValues={getValues}
              name={'tripReason'}
              label={'Motivo viagem'}
              disabled
              sm={12}
              md={12}
              lg={2}
              xl={2}
              actionRightButton={() =>
                showGenericModal(
                  'Motivos de viagem',
                  'Selecionar motivo',
                  '/salesrelease/generaldata?type=16&activeOnly=true',
                  'tripReason',
                  16,
                )
              }
            />

            <SalesReleasesFormInput
              register={register}
              setValue={setValue}
              getValues={getValues}
              name={'tripPoliticReason'}
              label={'Motivo desvio política de viagem'}
              disabled
              sm={12}
              md={12}
              lg={2}
              xl={2}
              actionRightButton={() =>
                showGenericModal(
                  'Motivos de Recusa',
                  'Selecionar motivo',
                  '/salesrelease/generaldata?type=17&activeOnly=true',
                  'tripPoliticReason',
                  17,
                )
              }
            />

            <SalesReleasesFormInput
              register={register}
              setValue={setValue}
              name={'fullFare'}
              label={'Full fare'}
              type={'number'}
              step={'.01'}
              sm={12}
              md={12}
              lg={1}
              xl={1}
            />

            <SalesReleasesFormInput
              register={register}
              setValue={setValue}
              name={'bestFare'}
              label={'Best fare'}
              type={'number'}
              step={'.01'}
              sm={12}
              md={12}
              lg={1}
              xl={1}
            />

            <SalesReleasesFormInput
              register={register}
              setValue={setValue}
              name={'bestFareDisp'}
              label={'Best fare disp.'}
              type={'number'}
              step={'.01'}
              sm={12}
              md={12}
              lg={1}
              xl={1}
            />
          </Row>

          <Row>
            <SalesReleasesFormInput
              register={register}
              setValue={setValue}
              getValues={getValues}
              name={'issuer'}
              label={'Emissor'}
              sm={12}
              md={12}
              lg={4}
              xl={4}
              actionRightButton={() =>
                showGenericModal(
                  'Emissor',
                  'Selecione o emissor',
                  '/salesrelease/generaldata?type=18&activeOnly=true',
                  'issuer',
                  18,
                )
              }
              disabled
              required
            />

            <SalesReleasesFormInput
              register={register}
              setValue={setValue}
              getValues={getValues}
              name={'promoter'}
              label={'Pomotor'}
              sm={12}
              md={12}
              lg={4}
              xl={4}
              actionRightButton={() =>
                showGenericModal(
                  'Pomotor',
                  'Selecione o promotor',
                  '/salesrelease/generaldata?type=18&activeOnly=true',
                  'promoter',
                  18,
                )
              }
              disabled
            />

            <SalesReleasesFormInput
              register={register}
              setValue={setValue}
              getValues={getValues}
              name={'manager'}
              label={'Gerente'}
              sm={12}
              md={12}
              lg={4}
              xl={4}
              actionRightButton={() =>
                showGenericModal(
                  'Pomotor',
                  'Selecione o promotor',
                  '/salesrelease/generaldata?type=18&activeOnly=true',
                  'manager',
                  18,
                )
              }
              disabled
            />
          </Row>

          <Row>
            <Col sm={12} md={12} lg={6} xl={5}>
              <Nav tabs style={{ position: 'relative' }}>
                <NavItem>
                  <NavLink
                    className={`${activeTabRoadMap === '1' ? 'active' : ''}`}
                    onClick={() => setActiveTabRoadMap('1')}
                  >
                    Roteiro
                  </NavLink>
                </NavItem>

                <Button
                  size='sm'
                  color='none'
                  className={'right-top-floating-button'}
                  onClick={showAddRoadmapModal}
                  hidden={!showAddRoadmapButton}
                >
                  <i className='fas fa-ellipsis-h' />
                </Button>
              </Nav>

              <TabContent activeTab={'1'}>
                <TabPane tabId={'1'}>
                  <Row>
                    <SalesReleasesFormInput
                      register={register}
                      setValue={setValue}
                      type='textarea'
                      name={'roadmap'}
                      disabled={lockRoadMap}
                      onChange={e => setLockRoadmap(!e?.target?.value)}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      required
                    />
                  </Row>
                </TabPane>
              </TabContent>
            </Col>

            <Col sm={12} md={12} lg={6} xl={7}>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={`${activeTabExternal === '1' ? 'active' : ''}`}
                    onClick={() => setActiveTabExternal('1')}
                  >
                    Externa
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    className={`${activeTabExternal === '2' ? 'active' : ''}`}
                    onClick={() => setActiveTabExternal('2')}
                  >
                    Interna
                  </NavLink>
                </NavItem>
              </Nav>

              <TabContent activeTab={activeTabExternal}>
                <TabPane tabId={'1'}>
                  <Row>
                    <SalesReleasesFormInput
                      register={register}
                      setValue={setValue}
                      type='textarea'
                      name={'externalInfo'}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                    />
                  </Row>
                </TabPane>

                <TabPane tabId={'2'}>
                  <Row>
                    <SalesReleasesFormInput
                      register={register}
                      setValue={setValue}
                      type='textarea'
                      name={'internalInfo'}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                    />
                  </Row>
                </TabPane>
              </TabContent>
            </Col>
          </Row>

          <Divisor margin='8px' />

          <Row>
            <Col sm={12} md={12} lg={12} xl={12} className='submit-area'>
              <Button
                size={'sm'}
                type='button'
                color='none'
                onClick={() => reset()}
              >
                Limpar
              </Button>

              <Button size={'sm'} type='submit' color='primary'>
                {loading && <Spinner size={'sm'} color={'#ccc'} />}

                {!loading && 'Salvar'}
              </Button>
            </Col>
          </Row>
        </fieldset>
      </SalesReleaseFormComponent>
    </SalesReleaseFormContainer>
  )
}
