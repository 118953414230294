import { useEffect, useState } from 'react'
import { GetAxios } from '~/services/api'
import DefaultForm from '~/components/DefaultForm'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import { Container } from '~/styles/container'
import Table from '~/components/Table'
import { LinkRegisterNewPanel } from '~/components/Link/styles'
import IconButton from '~/components/IconButton'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { v4 } from 'uuid'

export default function SchoolList() {
  const { axiosApis } = GetAxios()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [newData, setNewData] = useState([])
  const [nameFilter, setNameFilter] = useState('')
  const [dataOptions, setDataOptions] = useState([])
  const navigate = useNavigate()

  useEffect(() => {
    requestApiGet()
  }, [])

  async function requestApiGet() {
    try {
      setLoading(true)

      const responseGet = await axiosApis.get(
        `${process.env.REACT_APP_ENDPOINT_APIS}/event`,
      )

      const responseOptions = await axiosApis.options(
        `${process.env.REACT_APP_ENDPOINT_APIS}/institution`,
      )

      setDataOptions(responseOptions.data)

      setData(
        responseGet.data.reduce((acc, value) => {
          const obj = {
            agencyId: value.agencyId,
            createdAt: value.createdAt,
            desc: value.desc,
            destiny: value.destiny,
            end: value.end,
            id: value.id,
            image: value.image,
            institutions: responseOptions.data.filter(e =>
              value.institutions.includes(e.value),
            ),
            start: value.start,
            status: value.status,
            title: value.title,
            token: value.token,
            type: value.type,
            updatedAt: value.updatedAt,
            vacancies: value.vacancies,
          }
          acc.push(obj)
          return acc
        }, []),
      )

      setNewData(
        responseGet.data.reduce((acc, value) => {
          const obj = {
            agencyId: value.agencyId,
            createdAt: value.createdAt,
            desc: value.desc,
            destiny: value.destiny,
            end: value.end,
            id: value.id,
            image: value.image,
            institutions: responseOptions.data.filter(e =>
              value.institutions.includes(e.value),
            ),
            start: value.start,
            status: value.status,
            title: value.title,
            token: value.token,
            type: value.type,
            updatedAt: value.updatedAt,
            vacancies: value.vacancies,
          }
          acc.push(obj)
          return acc
        }, []),
      )

      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const createTravel = async eventId => {
    try {
      setLoading(true)

      await axiosApis.post(
        `${process.env.REACT_APP_ENDPOINT_APIS}/event/${eventId}`,
      )

      toast.success('Viagem criada com sucesso')
      setLoading(false)
    } catch (err) {
      setLoading(false)
    }
  }

  async function showDetails(e) {
    navigate('/pedagogical-event/list/event/details', { state: { id: e } })
  }
  async function showDocuments(e) {
    navigate('/pedagogical-event/documents/event/list', { state: { event: e } })
  }

  async function onSubmit(e) {
    if (e.name && e.institution) {
      return setData(
        newData.filter(
          e2 =>
            e2.title
              .toLowerCase()
              .replaceAll('á', 'a')
              .replaceAll('é', 'e')
              .replaceAll('í', 'i')
              .replaceAll('ó', 'o')
              .replaceAll('ú', 'ó')
              .replaceAll('â', 'a')
              .replaceAll('ê', 'e')
              .replaceAll('ô', 'o')
              .replaceAll('ã', 'a')
              .replaceAll('õ', 'o')
              .replaceAll('ç', 'c')
              .includes(
                e.name
                  .toLowerCase()
                  .replaceAll('á', 'a')
                  .replaceAll('é', 'e')
                  .replaceAll('í', 'i')
                  .replaceAll('ó', 'o')
                  .replaceAll('ú', 'ó')
                  .replaceAll('â', 'a')
                  .replaceAll('ê', 'e')
                  .replaceAll('ô', 'o')
                  .replaceAll('ã', 'a')
                  .replaceAll('õ', 'o')
                  .replaceAll('ç', 'c'),
              ) && e2.institutions.includes(e.institution),
        ),
      )
    }

    if (e.name) {
      return setData(
        newData.filter(e =>
          e.title
            .toLowerCase()
            .replaceAll('á', 'a')
            .replaceAll('é', 'e')
            .replaceAll('í', 'i')
            .replaceAll('ó', 'o')
            .replaceAll('ú', 'ó')
            .replaceAll('â', 'a')
            .replaceAll('ê', 'e')
            .replaceAll('ô', 'o')
            .replaceAll('ã', 'a')
            .replaceAll('õ', 'o')
            .replaceAll('ç', 'c')
            .includes(
              nameFilter
                .toLowerCase()
                .replaceAll('á', 'a')
                .replaceAll('é', 'e')
                .replaceAll('í', 'i')
                .replaceAll('ó', 'o')
                .replaceAll('ú', 'ó')
                .replaceAll('â', 'a')
                .replaceAll('ê', 'e')
                .replaceAll('ô', 'o')
                .replaceAll('ã', 'a')
                .replaceAll('õ', 'o')
                .replaceAll('ç', 'c'),
            ),
        ),
      )
    }

    if (e.institution) {
      return setData(
        newData.filter(e2 => e2.institutions.includes(e.institution)),
      )
    }
  }

  return (
    <Container>
      <h1 className='page-header'>
        Eventos
        <IconButton
          id={1}
          type={'info'}
          iconOverride={'fa fa-info-circle'}
          tooltipOverride={'Tela de Eventos, facilita a organização do grupo.'}
          style={{ fontSize: '12px' }}
        />
      </h1>

      <Panel>
        <PanelHeader noButton title={'Filtros'} />

        <PanelBody>
          <DefaultForm
            callbackSubmit={onSubmit}
            callbackReset={() => {
              setNameFilter('')
              requestApiGet()
            }}
            submitLabel={'Pesquisar'}
            inputs={[
              {
                type: 'select',
                name: 'institution',
                label: 'Grupo',
                placeholder: 'Selecione...',
                options: dataOptions,
                sm: 12,
                md: 6,
                lg: 4,
                xl: 4,
              },
              {
                type: 'text',
                name: 'name',
                label: 'Evento',
                placeholder: 'Evento',
                onChange: e => setNameFilter(e.target.value),
                sm: 12,
                md: 6,
                lg: 4,
                xl: 4,
              },
            ]}
          />
        </PanelBody>
      </Panel>

      <Panel>
        <PanelHeader title={'Resultado da Pesquisa'} noButton>
          <LinkRegisterNewPanel to='/pedagogical-event/create/event'>
            Criar Evento
          </LinkRegisterNewPanel>
        </PanelHeader>

        <PanelBody>
          <Table
            data={data}
            loading={loading}
            columns={[
              {
                Header: 'Nome',
                accessor: 'title',
                width: 200,
              },
              {
                Header: 'Tipo de evento',
                access: 'type',
                Cell: props => <p>{props.row.original.type}</p>,
                width: 120,
              },
              {
                Header: 'Grupos',
                accessor: 'institutions',
                Cell: props => (
                  <p>
                    {props.row.original.institutions
                      .map(e => e.label)
                      .join(' / ')}
                  </p>
                ),
                width: 200,
              },
              {
                Header: 'N° de vagas',
                access: 'vacancies',
                Cell: props => <p>{props.row.original.vacancies}</p>,
                width: 100,
              },
              {
                Header: 'Status',
                access: 'status',
                Cell: props => (
                  <span>{props.row.original.status ? 'Ativo' : 'Inativo'}</span>
                ),
                width: 100,
              },
              {
                Header: '',
                accessor: 'details',
                Cell: props => {
                  return (
                    <IconButton
                      id={v4()}
                      type={'details'}
                      onClick={() => showDetails(props.row.original.id)}
                    />
                  )
                },
                width: 20,
              },
              {
                Header: '',
                accessor: 'eye',
                Cell: props => {
                  return (
                    <IconButton
                      id={v4()}
                      type={'details'}
                      iconOverride={'fas fa-eye'}
                      tooltipOverride={'Visualizar'}
                      onClick={() =>
                        window.open(
                          `${location.origin}/event-subscription/${props.row.original.token}`,
                        )
                      }
                    />
                  )
                },
                width: 20,
              },
              {
                Header: '',
                accessor: 'copy',
                Cell: props => {
                  return (
                    <IconButton
                      id={v4()}
                      type={'details'}
                      tooltipOverride={'Copiar link'}
                      iconOverride={'fas fa-copy'}
                      onClick={() =>
                        navigator.clipboard.writeText(
                          `${location.origin}/event-subscription/${props.row.original.token}`,
                        )
                      }
                    />
                  )
                },
                width: 20,
              },
              {
                Header: '',
                accessor: 'docs',
                Cell: props => {
                  return (
                    <IconButton
                      id={v4()}
                      type={'file'}
                      onClick={() => showDocuments(props.row.original)}
                    />
                  )
                },
                width: 20,
              },
              {
                Header: '',
                accessor: 'travel',
                Cell: props => {
                  return (
                    <IconButton
                      id={v4()}
                      type={'travel'}
                      iconOverride={'fas fa-plane'}
                      tooltipOverride={'Cria viagem a partir desse evento'}
                      onClick={() => createTravel(props.row.original.id)}
                    />
                  )
                },
                width: 20,
              },
            ]}
            tableProps={{
              defaultPageSize: 10,
              hasFooter: false,
              hasPagination: true,
              loading: loading,
              resizable: true,
            }}
            tableStyle={{
              fontSize: '13px',
              cellPadding: '6px 4px',
            }}
            tableLabels={{
              loadingText: 'Carregando...',
              noDataText: 'Sem registros',
              nextButtonText: 'Próximo',
              previousButtonText: 'Anterior',
              pageOfText: 'de',
              pageSizeSelectText: 'registros',
              pageText: 'Página',
            }}
          />
        </PanelBody>
      </Panel>
    </Container>
  )
}
