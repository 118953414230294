import styled from 'styled-components'
import AnalyticsDashboard from '~/components/Analytics/Dashboard'
import HomeDashboardTitle from '../Title'

export default function HomeDashboardIndicators({ dashboards, loading }) {
  return (
    <Container>
      <HomeDashboardTitle title={'Indicadores'} />

      <AnalyticsDashboard
        data={dashboards}
        columnSizingPerRow={'50%'}
        loading={loading}
      />
    </Container>
  )
}

const Container = styled.div`
  width: calc(50% - 8px);
  min-width: 540px;
  max-width: 100%;
  padding: 4px;
  height: 300px;
  display: flex;
  flex-direction: column;

  .home-dashboard-indicators-title {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
  }

  .home-dashboard-indicators-title-info {
    font-size: 14px;
    font-weight: 600;
    color: ${props => props.theme.colors.primary};
  }

  @media (max-width: 920px) {
    height: auto;
  }
`
