import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import DefaultForm from '~/components/DefaultForm'
import PageTitle from '~/components/PageTitle'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import { GetAxios } from '~/services/api'
import { Container } from './styles'
import LoadingContainer from '~/components/LoadingContainer'
import {
  getParticipantInputs,
  getParticipantInputsMinimal,
  // getResponsibleInputs,
  // getEventInputs,
  // getSchoolInputs,
} from './constants'
// import SendToKanbanButton from '../SendToKanbanButton'
import { useSelector } from 'react-redux'
// import { JsonToExcel } from 'react-json-to-excel'

export default function SchoolDetails() {
  const { axiosApis } = GetAxios()
  const location = useLocation()
  const [participant, setParticipant] = useState()
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const user = useSelector(state => state.auth.user)

  useEffect(() => {
    loadSource()
  }, [])

  async function loadSource() {
    try {
      setLoading(true)

      const response = await axiosApis.get(
        `${process.env.REACT_APP_ENDPOINT_APIS}/eventSubscription/manage/participants/details/${location.state.id}`,
      )

      setParticipant(response.data)

      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const onSubmit = async data => {
    try {
      setLoading(true)

      const body = {
        Id: location.state.id,
        ...data,
      }

      await axiosApis.patch(
        `${process.env.REACT_APP_ENDPOINT_APIS}/eventSubscription/manage/participants/details`,
        body,
      )

      setLoading(false)
      navigate(-1)
    } catch (error) {
      setLoading(false)
    }
  }

  return (
    <Container>
      <PageTitle
        title={`${
          participant
            ? 'Participante - ' + participant.fullName
            : 'Participante'
        }`}
      />

      <Panel>
        <PanelHeader noButton></PanelHeader>

        <PanelBody>
          {participant && !loading && (
            <Container>
              {participant?.event?.type == 'outros' ||
              participant?.event?.type == 'incentivo' ? (
                <>
                  <PageTitle title='Dados do participante' head='h5' noButton />

                  <DefaultForm
                    callbackSubmit={onSubmit}
                    defaultValue={participant}
                    inputs={getParticipantInputsMinimal()}
                  />
                </>
              ) : (
                <>
                  {/* <Div>
                    <PageTitle title='Dados da viagem' head='h5' noButton />

                    <div style={{ display: 'flex' }}>
                      {participant && !loading && (
                        <SendToKanbanButton data={participant} />
                      )}
                      <LinkPrint
                        className='link'
                        to='/print-student-details'
                        state={{ participant: participant }}
                      >
                        Preparar Impressão
                      </LinkPrint>
                    </div>
                  </Div> */}

                  <DefaultForm
                    callbackSubmit={onSubmit}
                    defaultValue={participant}
                    inputs={getParticipantInputs(user.agencyName)}
                  />

                  {/* <PageTitle title='Dados do participante' head='h5' noButton />

                  <DefaultForm
                    defaultValue={participant}
                    disabled={loading}
                    inputs={getParticipantInputs(user.agencyName)}
                  />

                  <PageTitle
                    title='Dados do Responsável financeiro'
                    head='h5'
                    noButton
                  />

                  <DefaultForm
                    defaultValue={participant.responsible}
                    disabled={loading}
                    inputs={getResponsibleInputs()}
                  /> */}

                  {/* <PageTitle title='Dados do Grupo' head='h5' noButton />

                  <DefaultForm
                    defaultValue={participant.institutions[0]}
                    disabled={loading}
                    inputs={getSchoolInputs()}
                  /> */}
                </>
              )}
            </Container>
          )}

          {(!participant || loading) && <LoadingContainer />}
        </PanelBody>
      </Panel>
    </Container>
  )
}
