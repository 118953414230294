import { useEffect, useState } from 'react'
import { GetAxios } from '~/services/api'
import DefaultForm from '~/components/DefaultForm'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import { Container } from '~/styles/container'
import Table from '~/components/Table'
import { LinkEdit } from '~/components/Link/styles'
import { getDateFormated } from '~/functions/Date'
import { toast } from 'react-toastify'
import SendToKanbanButton from '../SendToKanbanButton'
import { RegisterNewButton2 } from '~/components/Button/styles'
import IconButton from '~/components/IconButton'
import { useSelector } from 'react-redux'
import { v4 } from 'uuid'
import { useNavigate } from 'react-router-dom'

export default function SchoolList() {
  const user = useSelector(state => state.auth.user)
  const { axiosApis } = GetAxios()
  const [loading, setLoading] = useState(false)
  const [eventOptions, setEventOptions] = useState([])
  const [institutionOptions, setInstitutionOptions] = useState([])
  const [data, setData] = useState([])
  const [dataForExport, setDataForExport] = useState([])
  const navigate = useNavigate()

  useEffect(() => {
    requestApiEventOptions()
    requestApiInstitutionOptions()
  }, [])

  async function requestApiEventOptions() {
    try {
      setLoading(true)

      const response = await axiosApis.options(
        `${process.env.REACT_APP_ENDPOINT_APIS}/event`,
      )

      setEventOptions(response.data)
      setLoading(false)
    } catch (error) {
      console.log(error)

      setLoading(false)
    }
  }
  async function requestApiInstitutionOptions() {
    try {
      setLoading(true)

      const response = await axiosApis.options(
        `${process.env.REACT_APP_ENDPOINT_APIS}/institution`,
      )

      setInstitutionOptions(response.data)
      setLoading(false)
    } catch (error) {
      console.log(error)

      setLoading(false)
    }
  }

  function onValidate(data) {
    if (!data || !data.event) {
      toast.info('Selecione um Evento.')

      return false
    }

    return true
  }

  async function onSubmit(data) {
    try {
      setLoading(true)

      const responseGetParticipant = await axiosApis.get(
        `${process.env.REACT_APP_ENDPOINT_APIS}/eventSubscription/manage/participants`,
        {
          params: {
            fullName: data.fullName ?? '',
            eventId: data.event ? data.event.value : undefined,
            institutionId: data.institution
              ? data.institution.value
              : undefined,
          },
        },
      )

      setData(responseGetParticipant.data)

      const responseGetDataExport = await axiosApis.get(
        `${process.env.REACT_APP_ENDPOINT_APIS}/eventSubscription/manage/participants/data-for-export`,
        {
          params: {
            fullName: data.fullName ?? '',
            eventId: data.event ? data.event.value : undefined,
            institutionId: data.institution
              ? data.institution.value
              : undefined,
          },
        },
      )

      const newList = []

      responseGetDataExport.data.map(e => {
        if (user.agencyName !== 'Wee Travel') {
          const { plana_sub, ...restOfDataToExport } = e

          newList.push(restOfDataToExport)
        } else {
          newList.push(e)
        }
      })

      setDataForExport(newList)

      return setLoading(false)
    } catch (error) {
      console.log(error)

      return setLoading(false)
    }
  }

  const saveAsExcelFile = (buffer, fileName) => {
    const fileSaver = import('file-saver')

    fileSaver.then(module => {
      if (module && module.default) {
        let EXCEL_TYPE =
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
        let EXCEL_EXTENSION = '.xlsx'
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        })

        module.default.saveAs(
          data,
          fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION,
        )
      }
    })
  }

  const exportExcel = () => {
    const xlsx = import('xlsx')

    xlsx.then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(dataForExport)
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] }
      const excelBuffer = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array',
      })

      saveAsExcelFile(excelBuffer, 'participants_details')
    })
  }

  async function showDetails(e) {
    navigate('/pedagogical-event/details', { state: { id: e } })
  }

  return (
    <Container>
      <h1 className='page-header'>
        Lista de Inscrições
        <IconButton
          id={1}
          type={'info'}
          iconOverride={'fa fa-info-circle'}
          tooltipOverride={
            'Tela de Lista de Inscrições, contem todos os inscritos por grupo, evitando ter as informações somente em email. A centralização das informações são todas no Tripmee.'
          }
          style={{ fontSize: '12px' }}
        />
      </h1>

      <Panel>
        <PanelHeader noButton title={'Filtros'} />

        <PanelBody>
          <DefaultForm
            // defaultValue={defaultValue}
            callbackSubmit={onSubmit}
            onValidate={onValidate}
            callbackReset={() => setData([])}
            submitLabel={'Pesquisar'}
            inputs={[
              {
                type: 'select',
                name: 'institution',
                label: 'Grupo',
                placeholder: 'Selecione...',
                options: institutionOptions,
                sm: 12,
                md: 6,
                lg: 4,
                xl: 4,
              },
              {
                type: 'select',
                name: 'event',
                label: 'Evento',
                placeholder: 'Selecione...',
                options: eventOptions,
                required: true,
                sm: 12,
                md: 6,
                lg: 4,
                xl: 4,
              },
              {
                type: 'text',
                name: 'fullName',
                label: 'Nome do viajante',
                placeholder: 'Nome do viajante',
                sm: 12,
                md: 6,
                lg: 4,
                xl: 4,
              },
            ]}
          />
        </PanelBody>
      </Panel>

      <Panel>
        <PanelHeader title={'Resultado da Pesquisa'} noButton>
          {dataForExport.length > 0 && (
            <RegisterNewButton2
              onClick={() => exportExcel()}
              disabled={loading}
            >
              Exportar
            </RegisterNewButton2>
          )}
        </PanelHeader>

        <PanelBody>
          <Table
            data={data}
            loading={loading}
            columns={[
              {
                Header: 'Posição de inscrição',
                accessor: 'id',
                Cell: props => <span>{props.row.original.index}º</span>,
                width: 150,
              },
              {
                Header: 'Data de Inscrição',
                accessor: 'createdAt',
                Cell: props =>
                  getDateFormated(
                    props.row.original.createdAt,
                    'DD/MM/YYYY HH:mm',
                  ),
                width: 150,
              },
              {
                Header: 'Viajante',
                accessor: 'fullName',
                width: 200,
              },
              {
                Header: 'Telefone',
                accessor: 'phone',
                width: 150,
              },
              {
                Header: 'Consultor Responsavel', //! Exibir somente quando quando o tipo do formulario for outros
                accessor: 'agentName',
                width: 150,
              },
              {
                Header: '',
                accessor: 'sendToKanban',
                Cell: props => {
                  return <SendToKanbanButton data={props.row.original} />
                },
                width: 200,
              },
              {
                Header: '',
                accessor: 'details',
                Cell: props => {
                  return (
                    <IconButton
                      id={v4()}
                      type={'details'}
                      onClick={() => showDetails(props.row.original.id)}
                    />
                  )
                },
                width: 20,
              },
            ]}
            tableProps={{
              defaultPageSize: 10,
              hasFooter: false,
              hasPagination: true,
              loading: loading,
              resizable: true,
            }}
            tableStyle={{
              fontSize: '13px',
              cellPadding: '6px 4px',
            }}
            tableLabels={{
              loadingText: 'Carregando...',
              noDataText: 'Sem registros',
              nextButtonText: 'Próximo',
              previousButtonText: 'Anterior',
              pageOfText: 'de',
              pageSizeSelectText: 'registros',
              pageText: 'Página',
            }}
          />
        </PanelBody>
      </Panel>
    </Container>
  )
}
