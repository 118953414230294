import styled from 'styled-components'

export const SpacingDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  font-size: 12px;
  overflow: hidden;
  position: relative;

  height: ${props => (props.type === 'day' ? '41px' : '72px')};
`

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: ${props => (props.type === 'day' ? 'row' : 'column')};
  align-items: flex-start;
  justify-content: flex-start;
`

export const EventDiv = styled.div`
  width: ${props => (props.type === 'day' ? 'auto' : '100%')};
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: ${props => (props.type === 'day' ? '0 2px 2px 0' : '0 5px 2px 0')};
  padding: ${props => (props.type === 'day' ? '0' : '2px 4px')};
  border-radius: 3px;
  background-color: ${props => (props.type === 'day' ? 'transparent' : '#fff')};
  color: #000;

  span {
    flex: 1;
    text-align: left;
    font-size: 12px;
    line-height: 12px;
    overflow: hidden;
    white-space: nowrap;
    margin: 0 0 0 6px;
  }
`

export const EventIndicatorStart = styled.div`
  padding: 3px;
  border-radius: 100%;
  background-color: #1bc25b;
`

export const EventIndicatorEnd = styled.div`
  padding: 3px;
  border-radius: 100%;
  background-color: #ff7d70;
`

export const DayNumber = styled.div`
  width: 100%;
  font-weight: bold;
  text-align: center;
`

export const Counter = styled.div`
  position: absolute;
  ${props =>
    props.type === 'day' ? 'right: 0; bottom: 0;' : 'right: 0; top: 0;'}
  padding: 5px;
  background-color: ${props => props.theme.colors.primary};
  color: #fff;
  border-radius: 100%;
  font-size: 11px;
  line-height: 10px;
  font-weight: bold;
`
