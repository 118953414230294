import { Route } from 'react-router-dom'
import SurveyForm from '~/pages/ApplicationForm/Survey'
import DownloadDoc from '~/pages/DownloadDoc'
import ProposalPdf from '~/pages/NewProposal/Print'
import RequestRefund from '~/pages/RequestRefund'
import PrintStudentDetails from '~/pages/PedagogicalEvent/Details/printStudentDetails'
import ChoiceForm from '~/pages/ApplicationForm'
import FreeTrial from '~/pages/FreeTrial'
import FreeTrialVisual from '~/pages/FreeTrial/visual'
import FreeTrialRdsin from '~/pages/FreeTrial/rdsin'
import Support from '~/pages/Support'
import TravelResume from '~/pages/TravelResume'
import LongForm from '~/pages/RequestForm/longForm'
import ShortForm from '~/pages/RequestForm/shortForm'

export default function BaseRoutes() {
  return (
    <>
      <Route path='/proposta/:token' exact element={<ProposalPdf />} />

      <Route path='/travel-resume/:token' exact element={<TravelResume />} />

      <Route path='/travel-survey/:token' exact element={<SurveyForm />} />

      <Route
        path='/download/doc/:token/:type'
        exact
        element={<DownloadDoc />}
      />

      <Route path='/request-refund/:token' exact element={<RequestRefund />} />

      <Route path='/event-subscription/:token' exact element={<ChoiceForm />} />

      <Route path='/free-trial' exact element={<FreeTrial />} />

      <Route path='/free-trial/visual' exact element={<FreeTrialVisual />} />

      <Route path='/free-trial/rdsin' exact element={<FreeTrialRdsin />} />

      <Route path='/support' exact element={<Support />} />

      <Route path='/request-form/:token' exact element={<LongForm />} />

      <Route path='/request-form-short/:token' exact element={<ShortForm />} />

      <Route
        path='/print-student-details'
        exact
        element={<PrintStudentDetails />}
      />
    </>
  )
}
