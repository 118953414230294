import IconButton from '~/components/IconButton'

export default function Inputs(
  isOperations = false,
  generateLink = () => {},
  showDetails = () => {},
  handleSubmitDelete = () => {},
  showLinkModal = () => {},
) {
  const inputs = [
    {
      Header: 'Itinerário',
      accessor: 'title',
      width: 125,
    },
    {
      Header: 'Viajante principal',
      accessor: 'traveler',
      width: 100,
    },
    {
      Header: 'Consultor',
      accessor: 'agent',
      width: 100,
    },
    {
      Header: 'Origem',
      accessor: 'origin',
      width: 150,
    },
    {
      Header: 'Viajantes',
      accessor: 'passengers',
      width: 75,
    },
    {
      Header: 'Dias',
      accessor: 'days',
      width: 60,
    },
    {
      Header: 'Início',
      accessor: 'start',
      // Cell: props => {
      //   const dateStart = getDateFull(props.row.original.start)
      //   return dateStart.day + '/' + dateStart.month + '/' + dateStart.year
      // },
      width: 100,
    },
    {
      Header: 'Fim',
      accessor: 'end',
      // Cell: props => {
      //   const dateEnd = getDateFull(props.row.original.end)
      //   return dateEnd.day + '/' + dateEnd.month + '/' + dateEnd.year
      // },
      width: 100,
    },
    {
      Header: 'Agência',
      accessor: 'belongingAgency',
      width: 100,
    },
    {
      Header: '',
      accessor: 'detalhes-viagem',
      Cell: props => {
        return (
          <IconButton
            id={'button-travel-details-' + props.row.index}
            type={'details'}
            onClick={() => showDetails(props.row.original.id)}
          />
        )
      },
      width: 20,
    },
    {
      Header: '',
      accessor: 'link-viagem',
      Cell: props => {
        return (
          <IconButton
            id={'button-travel-link-copy-' + props.row.index}
            type={'details'}
            iconOverride={'fas fa-copy'}
            tooltipOverride={'Copiar link da viagem'}
            onClick={() => showLinkModal(props.row.original.id)}
          />
        )
      },
      width: 20,
    },
    {
      Header: '',
      accessor: 'roteiro-viagem',
      Cell: props => {
        return (
          <IconButton
            id={'button-travel-link-open-' + props.row.index}
            iconOverride={'fas fa-external-link-alt'}
            tooltipOverride={'Abrir link da viagem'}
            type={'details'}
            onClick={() => generateLink(props.row.original.id)}
          />
        )
      },
      width: 20,
    },
    {
      Header: '',
      accessor: 'delete-viagem',
      Cell: props => {
        return (
          <IconButton
            id={'button-travel-link-delete-' + props.row.index}
            type={'trash'}
            tooltipOverride={'Apagar viagem'}
            onClick={() => handleSubmitDelete(props.row.original.id)}
          />
        )
      },
      width: 20,
    },
  ]

  if (isOperations) return inputs

  return inputs.filter(input => input.accessor != 'belongingAgency')
}
