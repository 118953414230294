import { Tooltip } from 'reactstrap'
import styled from 'styled-components'
import AnalyticsTable from '~/components/Analytics/Table'
import HomeDashboardTitle from '../Title'

export default function HomeDashboardTableBirthdays({
  birthdays,
  loading,
  sendHappyBirthdayMessageByWpp,
  allowHappyBirthdayMessage,
}) {
  return (
    <Container>
      <HomeDashboardTitle title={'Aniversariantes'} />

      <AnalyticsTable
        data={birthdays}
        loading={loading}
        thin={true}
        columns={[
          {
            Header: 'Nome',
            accessor: 'name_viajante',
            width: 200,
          },
          {
            Header: 'Telefone',
            accessor: 'fone',
            width: 200,
          },
          {
            Header: 'E-mail',
            accessor: 'email',
            width: 300,
          },
          {
            Header: 'Aniversário',
            accessor: 'birthday',
            width: 100,
          },
          {
            Header: '',
            accessor: 'whatsappSend',
            Cell: props => {
              return (
                <div>
                  {props.row.original.fone != 0 &&
                  props.row.original.fone != null &&
                  props.row.original.sendHappyMessage ? (
                    <div>
                      {!allowHappyBirthdayMessage && (
                        <Tooltip
                          target={`wppbutton${props.row.original.id}`}
                          text='Habilite o botão adicionando um texto para envio de mensagens'
                        />
                      )}

                      <WppButton
                        id={`wppbutton${props.row.original.id}`}
                        type='button'
                        style={
                          !allowHappyBirthdayMessage
                            ? { color: '#aaa', cursor: 'default' }
                            : {}
                        }
                        onClick={
                          allowHappyBirthdayMessage
                            ? () =>
                                sendHappyBirthdayMessageByWpp(
                                  props.row.original.id,
                                  props.row.original.fone,
                                  props.row.original.name_viajante,
                                )
                            : () => {}
                        }
                      >
                        Parabenizá-lo(a) por WhatsApp
                      </WppButton>
                    </div>
                  ) : (
                    <b></b>
                  )}
                </div>
              )
            },
            width: 160,
          },
        ]}
      />
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 540px;
  max-width: 100%;
  padding: 4px;

  .table-responsive {
    max-height: 200px;
    overflow: auto;
  }

  @media (max-width: 920px) {
    height: auto;
  }
`

export const WppButton = styled.button`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding: 0px 12px;
  font-weight: bold;
  border: none;
  border-radius: 4px;
  text-decoration: none;
  color: #333;
  background-color: transparent;
  color: #1bc25b;

  &:hover {
    color: #1bc25b;
    background-color: #00000011;
  }
`
