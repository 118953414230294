import { Badge, Button, NavLink } from 'reactstrap'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  font-size: 14px;
  word-break: break;
`

export const TagContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  gap: 4px;
  align-items: center;
  flex-wrap: wrap;
`

export const TagBadge = styled(Badge)`
  background-color: ${props => props.theme.colors.primary} !important;
`

export const AddBadge = styled(Badge)`
  color: ${props => props.theme.colors.primary} !important;
  background-color: whitesmoke !important;
  cursor: pointer;
`

export const CustomerName = styled.span`
  text-align: center;
  font-size: 1.2em;
  word-break: break;
  font-weight: 600;
`

export const CustomerPhone = styled.span`
  text-align: center;
  font-size: 1.2em;
  word-break: break-all;
`

export const CustomerEmail = styled.span`
  text-align: center;
  font-size: 1.2em;
  word-break: break-all;
`
export const ServiceButton = styled(NavLink)`
  height: 38px;
  padding: 0;
  color: #555;
  background: none;
  border: none;
  font-size: 0.8em;
  margin-left: 7px;
  outline: 0;
  outline-style: none;
  display: inline;
  cursor: pointer;
`
