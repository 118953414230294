import { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import { GetAxios } from '~/services/api'
import { toast } from 'react-toastify'
import { Container } from './styles'
import DefaultForm from '~/components/DefaultForm'
import { getDateFormated } from '~/functions/Date'
import styled from 'styled-components'
import LoadingContainer from '~/components/LoadingContainer'
import Divisor from '~/components/Divisor'

export default function Create({ toCreate, onClose }) {
  const { axiosApis } = GetAxios()
  const { travel } = useOutletContext()
  const [loading, setLoading] = useState(false)
  const [loadingSearchLocator, setLoadingSearchLocator] = useState(false)
  const [loadingSearchAirport, setLoadingSearchAirport] = useState(false)
  const [airsToSave, setAirsToSave] = useState([])
  const [showMainForm, setShowMainForm] = useState(false)
  const [defaultValue, setDefaultValue] = useState({
    departure: getDateFormated(toCreate.date, 'YYYY-MM-DD'),
    arrival: getDateFormated(toCreate.date, 'YYYY-MM-DD'),
    timeDeparture: getDateFormated(toCreate.date, '00:00'),
    timeArrival: getDateFormated(toCreate.date, '00:00'),
  })

  useEffect(() => {
    async function loadSource() {
      try {
        setLoading(true)

        const dataIntegrations = await axiosApis.options(
          `${process.env.REACT_APP_ENDPOINT_APIS}/integrationv3`,
        )

        if (dataIntegrations.data.length === 0) {
          setShowMainForm(true)
        }

        setLoading(false)
      } catch (error) {
        setLoading(false)
        console.log(error)
      }
    }

    loadSource()
  }, [])

  async function handleSubmit(e) {
    try {
      setLoading(true)

      await axiosApis.post(`${process.env.REACT_APP_ENDPOINT_APIS}/travelair`, {
        cia: e.cia,
        ciaIata: e.ciaIata,
        seat: e.seat,
        locator: e.locator,
        flight: e.flight,
        originPurchase: e.originPurchase,

        origin: e.origin,
        originIata: e.originIata,
        departure: e.departure + ' ' + e.timeDeparture,
        airportOrigin: e.airportOrigin,

        destiny: e.destiny,
        destinyIata: e.destinyIata,
        arrival: e.arrival + ' ' + e.timeArrival,
        airportDestiny: e.airportDestiny,
        description: e.description,

        tips: e.tips,
        travelId: travel.id,
      })

      toast.success('Aéreo criado com sucesso!')

      setLoading(false)

      onClose(true)
    } catch (error) {
      setLoading(false)

      toast.error('Não foi possível criar o aéreo.')
    }
  }

  async function handleSubmitAll() {
    try {
      setLoading(true)

      for (let i = 0; i < airsToSave.length; i++) {
        await axiosApis.post(
          `${process.env.REACT_APP_ENDPOINT_APIS}/travelair`,
          {
            cia: airsToSave[i].cia,
            ciaIata: airsToSave[i].ciaIata,
            provider: airsToSave[i].provider,
            providerIata: airsToSave[i].providerIata,
            seat: airsToSave[i].seat,
            locator: airsToSave[i].locator,
            flight: airsToSave[i].flight,
            originPurchase: airsToSave[i].originPurchase,

            origin: airsToSave[i].origin,
            airportOrigin: airsToSave[i].airportOrigin,
            originIata: airsToSave[i].originIata,
            departure: airsToSave[i].departure,

            destiny: airsToSave[i].destiny,
            airportDestiny: airsToSave[i].airportDestiny,
            destinyIata: airsToSave[i].destinyIata,
            arrival: airsToSave[i].arrival,
            // description: airsToSave[i].description,

            tips: airsToSave[i].tips,
            travelId: travel.id,
          },
        )
      }

      toast.success('Aéreos salvos com sucesso!')

      setLoading(false)

      onClose(true)
    } catch (error) {
      setLoading(false)

      toast.error('Não foi possível salvar os aéreos.')
    }
  }

  async function onSearchLocator(e) {
    try {
      setLoadingSearchLocator(true)

      const res = await axiosApis.get(
        `${process.env.REACT_APP_ENDPOINT_APIS}/sales/air`,
        {
          params: {
            locator: e.locator,
          },
        },
      )

      if (res.data.length === 0) {
        setLoadingSearchLocator(false)

        setLoading(false)

        return toast.info(
          'Localizador não encontrado. Tente novamente ou preencha os dados manualmente!',
        )
      }

      setAirsToSave(res.data)

      setLoadingSearchLocator(false)
    } catch (error) {
      setLoadingSearchLocator(false)

      setAirsToSave([])

      console.log(error)

      return toast.info(
        'Localizador não encontrado. Tente novamente ou preencha os dados manualmente!',
      )
    }
  }

  async function onSearchLocatorCancel() {
    setShowMainForm(true)

    setAirsToSave([])
  }

  async function onChangeIata(e) {
    if (!e.target.value) {
      return
    }

    try {
      setLoadingSearchAirport(true)

      const res = await axiosApis.get(
        `${process.env.REACT_APP_ENDPOINT_APIS}/travelair/search-airport`,
        {
          params: {
            airportIata: e.target.value,
          },
        },
      )

      if (!res.data) {
        setLoadingSearchAirport(false)

        return toast.info('Aeroporto não encontrado.')
      }

      if (e.target.name === 'originIata') {
        setDefaultValue({
          originIata: res.data.id,
          airportOrigin: res.data.name,
          origin: res.data.city,
        })
      }

      if (e.target.name === 'destinyIata') {
        setDefaultValue({
          destinyIata: res.data.id,
          airportDestiny: res.data.name,
          destiny: res.data.city,
        })
      }

      setLoadingSearchAirport(false)
    } catch (error) {
      setLoadingSearchAirport(false)

      return toast.info('Aeroporto não encontrado.')
    }
  }

  async function onCancelMainForm() {
    setShowMainForm(false)
  }

  return (
    <Container>
      {loading && <LoadingContainer color='#ccc' size='lg' height='200px' />}

      {!loading && (
        <>
          {!showMainForm && (
            <>
              <DefaultForm
                callbackSubmit={onSearchLocator}
                callbackCancel={onSearchLocatorCancel}
                submitLabel={'Localizar'}
                cancelLabel={'Preencher dados manualmente'}
                loading={loadingSearchLocator}
                disabled={loadingSearchLocator}
                inputs={[
                  {
                    type: 'text',
                    name: 'locator',
                    maxLength: 50,
                    label: 'Localizador',
                    required: true,
                    sm: 12,
                  },
                ]}
              />

              <Divisor margin='8px 0' border />

              {airsToSave.length === 0 && (
                <p>
                  <b>
                    Localize acima seus aéreos ou preencha os dados manualmente.
                  </b>
                </p>
              )}

              {airsToSave.length > 0 && (
                <>
                  <AirsList>
                    {airsToSave.map((e, i) => {
                      return (
                        <AirsItem key={i}>
                          <AirsItemHeader>
                            <div>
                              <span>{e.originIata}</span>

                              <span
                                style={{
                                  fontWeight: 'normal',
                                }}
                              >
                                {e.origin}
                              </span>

                              <span>
                                {getDateFormated(e.departure, 'DD/MM/YYYY')}
                              </span>

                              <span>
                                {getDateFormated(e.departure, 'HH:mm')}
                              </span>
                            </div>

                            <i className={'fas fa-plane'} />

                            <div>
                              <span>{e.destinyIata}</span>

                              <span
                                style={{
                                  fontWeight: 'normal',
                                }}
                              >
                                {e.destiny}
                              </span>

                              <span>
                                {getDateFormated(e.arrival, 'DD/MM/YYYY')}
                              </span>

                              <span>{getDateFormated(e.arrival, 'HH:mm')}</span>
                            </div>
                          </AirsItemHeader>

                          <p>
                            <b>
                              {e.originPurchase} ({e.originPurchaseLocator})
                            </b>
                          </p>

                          <AirsItemContent>
                            <li>
                              <span>Fornecedor</span>

                              <span>{e.provider}</span>
                            </li>

                            <li>
                              <span>Cia aérea</span>

                              <span>
                                {e.cia} ({e.ciaIata})
                              </span>
                            </li>

                            <li>
                              <span>Voo</span>

                              <span>{e.flight}</span>
                            </li>

                            <li>
                              <span>Bilhete/Localizador</span>

                              <span>{e.locator}</span>
                            </li>
                          </AirsItemContent>
                        </AirsItem>
                      )
                    })}
                  </AirsList>

                  <DefaultForm inputs={[]} callbackSubmit={handleSubmitAll} />
                </>
              )}
            </>
          )}

          {showMainForm && (
            <DefaultForm
              callbackSubmit={handleSubmit}
              callbackCancel={onCancelMainForm}
              cancelLabel={'Voltar'}
              defaultValue={defaultValue}
              loading={loadingSearchAirport}
              disabled={loadingSearchAirport}
              inputs={[
                {
                  type: 'text',
                  name: 'ciaIata',
                  maxLength: 5,
                  label: 'Código cia aérea',
                  sm: 3,
                  md: 3,
                  lg: 2,
                  xl: 2,
                },
                {
                  type: 'text',
                  name: 'cia',
                  maxLength: 100,
                  label: 'Nome cia aérea',
                  sm: 6,
                  md: 6,
                  lg: 3,
                  xl: 3,
                },
                {
                  type: 'number',
                  name: 'flight',
                  label: 'Número do Voo',
                  sm: 3,
                  md: 3,
                  lg: 2,
                  xl: 2,
                },
                {
                  type: 'text',
                  name: 'seat',
                  maxLength: 100,
                  label: 'Assento',
                  sm: 3,
                  md: 3,
                  lg: 2,
                  xl: 2,
                },
                {
                  type: 'text',
                  name: 'locator',
                  maxLength: 100,
                  label: 'Bilhete/Localizador',
                  sm: 3,
                  md: 3,
                  lg: 3,
                  xl: 3,
                },
                {
                  type: 'text',
                  name: 'originPurchase',
                  maxLength: 100,
                  label: 'Fornecedor',
                  sm: 6,
                  md: 6,
                  lg: 6,
                  xl: 6,
                },
                {
                  type: 'value',
                  name: 'tips',
                  label: 'Amenidades',
                  sm: 12,
                  md: 12,
                  lg: 6,
                  xl: 6,
                },
                {
                  empty: true,
                  sm: 12,
                  text: '<h6>Embarque</h6>',
                },
                {
                  type: 'text',
                  name: 'originIata',
                  label: 'IATA',
                  callbackSearch: onChangeIata,
                  searchButton: true,
                  sm: 3,
                  md: 3,
                  lg: 2,
                  xl: 2,
                },
                {
                  type: 'text',
                  name: 'airportOrigin',
                  label: 'Nome do aeroporto',
                  maxLength: 50,
                  sm: 5,
                  md: 5,
                  lg: 3,
                  xl: 3,
                },
                {
                  type: 'text',
                  name: 'origin',
                  label: 'Cidade',
                  maxLength: 50,
                  sm: 4,
                  md: 4,
                  lg: 3,
                  xl: 3,
                },
                {
                  type: 'date',
                  name: 'departure',
                  label: 'Data',
                  required: true,
                  sm: 6,
                  md: 6,
                  lg: 2,
                  xl: 2,
                },
                {
                  type: 'time',
                  name: 'timeDeparture',
                  label: 'Horário',
                  required: true,
                  sm: 6,
                  md: 6,
                  lg: 2,
                  xl: 2,
                },
                {
                  empty: true,
                  sm: 12,
                  text: '<h6>Desembarque</h6>',
                },
                {
                  type: 'text',
                  name: 'destinyIata',
                  label: 'IATA',
                  callbackSearch: onChangeIata,
                  searchButton: true,
                  sm: 3,
                  md: 3,
                  lg: 2,
                  xl: 2,
                },
                {
                  type: 'text',
                  name: 'airportDestiny',
                  label: 'Nome do aeroporto',
                  maxLength: 50,
                  sm: 5,
                  md: 5,
                  lg: 3,
                  xl: 3,
                },
                {
                  type: 'text',
                  name: 'destiny',
                  label: 'Cidade',
                  maxLength: 50,
                  sm: 4,
                  md: 4,
                  lg: 3,
                  xl: 3,
                },
                {
                  type: 'date',
                  name: 'arrival',
                  label: 'Data',
                  required: true,
                  sm: 6,
                  md: 6,
                  lg: 2,
                  xl: 2,
                },
                {
                  type: 'time',
                  name: 'timeArrival',
                  label: 'Horário',
                  required: true,
                  sm: 6,
                  md: 6,
                  lg: 2,
                  xl: 2,
                },
                {
                  type: 'textarea',
                  name: 'description',
                  label: 'Observação',
                  sm: 12,
                  md: 12,
                  lg: 12,
                  xl: 12,
                },
              ]}
            />
          )}
        </>
      )}
    </Container>
  )
}

const AirsList = styled.ul`
  width: 100%;
  max-height: 400px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
  margin: 0;
  padding: 0;
  overflow: auto;
`

const AirsItem = styled.li`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 8px;
  border-radius: 8px;
  background-color: #eee;
  color: #333;
  list-style-type: none;
`

const AirsItemHeader = styled.div`
  width: 100%;
  margin: 0 0 8px;
  max-width: 600px;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;

  span {
    font-size: 1rem;
  }

  > * {
    &:first-child {
      display: flex;
      flex-direction: column;
    }

    &:last-child {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }
`

const AirsItemContent = styled.ul`
  margin: 0 0 8px;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;

  li {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    > * {
      &:last-child {
        font-weight: bold;
      }
    }
  }
`
