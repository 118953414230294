import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { apiRequest } from '~/apiService'
import { getDashboardPlaceholders } from '~/components/Analytics/Dashboard'

export default function useAgencyHome({ filters }) {
  const [loading, setLoading] = useState(false)
  const dashboardsPlaceholders = getDashboardPlaceholders(4)
  const [dashboards, setDashboards] = useState(dashboardsPlaceholders)
  const [embarques, setEmbarques] = useState([])
  const [chatCenterChart, setChatCenterChat] = useState()
  const [reminders, setReminders] = useState([])
  const [birthdays, setBirthdays] = useState([])

  const [allowHappyBirthdayMessage, setAllowHappyBirthdayMessage] = useState()

  const params = {
    start: filters?.start ?? undefined,
    end: filters?.end ?? undefined,
  }

  useEffect(() => {
    getData()

    getHappyBirthdayValidation()
  }, [filters])

  const getData = async () => {
    try {
      setLoading(true)

      const [
        resDashboards,
        resEmbarques,
        resChatCenterChart,
        resReminders,
        resBirthdays,
      ] = await Promise.allSettled([
        apiRequest('get', 'home/dashboards', params),
        apiRequest('get', 'home/flights', params),
        apiRequest('get', 'home/chatcenter/chart', params),
        apiRequest('get', 'home/reminders', params),
        apiRequest('get', 'home/birthdays', params),
      ])

      if (resDashboards?.value?.data) {
        setDashboards(resDashboards.value.data)
      }

      if (resEmbarques?.value?.data) {
        setEmbarques(resEmbarques.value.data)
      }

      if (resChatCenterChart?.value?.data) {
        setChatCenterChat(resChatCenterChart.value.data)
      }

      if (resReminders?.value?.data) {
        setReminders(resReminders.value.data)
      }

      if (resBirthdays?.value?.data) {
        setBirthdays(resBirthdays.value.data)
      }
    } catch (error) {
      toast.info('Dados não encontrados. Tente mais tarde!')
    } finally {
      setLoading(false)
    }
  }

  const markTaskAsDone = async id => {
    try {
      setLoading(true)

      await apiRequest('put', `/reminder/done/${id}`, {})

      await getData()

      setLoading(false)
    } catch (err) {
      toast.info(
        'Não foi possível marcar a tarefa como feita, tente mais tarde!',
      )

      setLoading(false)
    }
  }

  async function getHappyBirthdayValidation() {
    try {
      const response = await apiRequest('get', `/agency/AllUsers`, undefined)

      setAllowHappyBirthdayMessage(response.data.happyBirthDay)
    } catch (error) {
      console.log(error)
    }
  }

  async function sendHappyBirthdayMessageByWpp(id, fone, name_viajante) {
    try {
      if (window.confirm('Deseja enviar a mensagem de aniversário?')) {
        const body = {
          id,
          fone,
          name_viajante,
        }

        await apiRequest('post', `/quotation/happysend-wpp`, body)

        await getData()

        toast.success('Mensagem enviada com sucesso!')
      }
    } catch (err) {
      if (err.response && err.response.data) {
        toast.info(err.response.data)

        console.log(err.response)
      }
    }
  }

  return {
    loading,
    dashboards,
    embarques,
    chatCenterChart,
    reminders,
    birthdays,
    markTaskAsDone,
    sendHappyBirthdayMessageByWpp,
    allowHappyBirthdayMessage,
  }
}
