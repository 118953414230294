import styled from 'styled-components'
import CalendarDefault from '~/components/DefaultCalendar'
import IconButton from '~/components/IconButton'
import { getDateFormated } from '~/functions/Date'
import { CalendarDay } from '~/pages/Schedule/components/CalendarDay'
import HomeDashboardTitle from '../Title'

export default function HomeDashboardCalendar({
  filters,
  onChangeFilters,
  defaultFilters,
}) {
  async function clearDateFilters() {
    onChangeFilters({ start: defaultFilters.start, end: defaultFilters.end })
  }

  async function onClickDay(date) {
    if (date) {
      const start = getDateFormated(date, 'YYYY-MM-DD') + ' 00:00:00'
      const end = getDateFormated(date, 'YYYY-MM-DD') + ' 23:59:59'

      onChangeFilters({ start, end })
    } else {
      await clearDateFilters()
    }
  }

  async function onChangeMonth(date) {
    console.log(date)
  }

  const renderDay = (locale, date) => {
    return <CalendarDay entitys={[]} locale={locale} date={date} type={'day'} />
  }

  return (
    <Container>
      <div className='home-dashboard-indicators-title'>
        <HomeDashboardTitle title={'Planejamento'} />

        {filters && filters.start && (
          <span className='home-dashboard-indicators-title-info'>
            Filtro Aplicado:{' '}
            {filters.start && getDateFormated(filters.start, 'DD/MM/YY')}{' '}
            {filters.end &&
              getDateFormated(filters.end, 'DD/MM/YY') !==
                getDateFormated(filters.start, 'DD/MM/YY') &&
              `- ${getDateFormated(filters.end, 'DD/MM/YY')}`}
            <IconButton
              id={'home-dashboard-remove-filter-date'}
              type={'remove'}
              tooltipOverride={'Limpar filtros'}
              iconOverride={'fas fa-times'}
              onClick={clearDateFilters}
            />
          </span>
        )}
      </div>

      <CalendarDefault
        onClickDay={onClickDay}
        onChangeMonth={onChangeMonth}
        renderDay={renderDay}
      />
    </Container>
  )
}

const Container = styled.div`
  width: calc(50% - 8px);
  min-width: 540px;
  max-width: 100%;
  padding: 4px;
  height: 360px;
  display: flex;
  flex-direction: column;

  .home-dashboard-indicators-title {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
  }

  .home-dashboard-indicators-title-info {
    font-size: 14px;
    font-weight: 600;
    color: ${props => props.theme.colors.primary};
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: flex-end;

    button {
      height: 21px;
      padding: 0 8px;
    }
  }
`
