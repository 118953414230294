import PageTitle from '~/components/PageTitle'

export default function HomeDashboardTitle({ title, desc }) {
  return (
    <PageTitle
      title={title}
      desc={desc}
      head={'h6'}
      noButton
      padding={'0 0 0 4px'}
    />
  )
}
