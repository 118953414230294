import styled from 'styled-components'

export const container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 1.1em;
  flex: 1;
`

export const item = styled.div`
  padding: 4px;
  width: ${props => props.columnSizingPerRow};

  @media screen and (max-width: 920px) {
    width: 100%;
  }
`

export const itemContent = styled.div`
  background: #fdfdfd;
  border: 1px solid #ccc;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 120px;

  @media screen and (max-width: 920px) {
    flex-direction: column;
  }
`

export const label = styled.p`
  font-weight: normal;
  border-bottom: 1px solid #ccc;
  width: 100%;
  text-align: left;
  padding: 8px;
  margin: 0;
  font-weight: 500;
`

export const subLabel = styled.p`
  font-size: 1em;
`

export const values = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-end;
  padding: 8px;
  justify-content: right;
`

export const value = styled.span`
  font-size: 1.6em;
  font-weight: bold;
`
