import React, { useState, useEffect } from 'react'
import {
  useTable,
  useExpanded,
  usePagination,
  useBlockLayout,
  useResizeColumns,
} from 'react-table'
import PropTypes from 'prop-types'
import { Row, Col, Tooltip } from 'reactstrap'
import {
  Container,
  SubContainer,
  Pagination,
  PageSelector,
  InfoContent,
  InfoContentFlag,
  PaginationThin,
} from './styles'

import {
  defaultTableProps,
  defaultTableStyle,
  defaultTableLabels,
} from './constants'
import styled from 'styled-components'
import IconButton from '../IconButton'

function Table({
  columns,
  data,
  tableLabels,
  tableProps,
  tableStyle,
  subComponent,
  loading = false,
  count,
  limit,
  actualPage,
  onChangePage,
  onChangeLimit,
  thin = false,
}) {
  const [tableColumns, setTableColumns] = useState([])

  useEffect(() => {
    let newTableColumns = columns.filter(x => !x.hide)

    setTableColumns(newTableColumns)

    return () => {
      setTableColumns([])
    }
  }, [columns])

  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 36,
      maxWidth: 400,
    }),
    [],
  )

  const tableConfig = {
    tableLabels: {
      ...defaultTableLabels,
      ...tableLabels,
    },
    tableProps: {
      ...defaultTableProps,
      ...tableProps,
    },
    tableStyle: {
      ...defaultTableStyle,
      ...tableStyle,
    },
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    // rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    // pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    resetResizing,
    // visibleColumns,
    // isAllRowsExpanded,
    toggleAllRowsExpanded,
    // getToggleAllRowsExpandedProps,
    // toggleRowExpanded,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns: tableColumns,
      data,
      defaultColumn,
      initialState: {
        pageIndex: 0,
        pageSize: tableConfig.tableProps.defaultPageSize,
        autoResetExpanded: true,
      },
    },
    useExpanded,
    usePagination,
    useBlockLayout,
    useResizeColumns,
  )

  const [pageChanged, setPageChanged] = useState(0)

  useEffect(() => {
    function setPageOfTable() {
      const page = pageChanged ? Number(pageChanged - 1) : 0

      return gotoPage(page)
    }

    setPageOfTable()
  }, [pageChanged])

  useEffect(() => {
    resetResizing()
  }, [data])

  function handleOthersRows() {
    let otherRows = []

    for (var i = 0; i < pageSize - page.length; i++) {
      otherRows.push(
        <tr key={i}>
          <td>&nbsp;</td>
        </tr>,
      )
    }

    return otherRows
  }

  async function handlePreviousPage() {
    if (actualPage && count && limit) {
      onChangePage(actualPage - 1)
    }

    previousPage()
  }

  async function handleNextPage() {
    if (actualPage && count && limit) {
      onChangePage(actualPage + 1)
    }

    nextPage()
  }

  async function handleToPage(e) {
    if (actualPage && count && limit) {
      onChangePage(e)
    }
  }

  async function handlePageSize(e) {
    if (actualPage && count && limit) {
      onChangeLimit(Number(e.target.value))
    }

    setPageSize(Number(e.target.value))
  }

  return (
    <>
      <Container tableStyle={tableConfig.tableStyle} empty={data.length === 0}>
        {data.length === 0 && (
          <InfoContent>
            <InfoContentFlag>
              {tableConfig.tableLabels.noDataText}
            </InfoContentFlag>
          </InfoContent>
        )}

        {(tableConfig.tableProps.loading || loading) && (
          <InfoContent>
            <InfoContentFlag>
              {tableConfig.tableLabels.loadingText}
            </InfoContentFlag>
          </InfoContent>
        )}

        <div
          className={`
            table-responsive
          `}
          style={{
            overflow: tableConfig.tableProps.noOverflow ? 'visible' : 'auto',
            scrollBehavior: 'smooth',
            scrollbarWidth: thin ? 'thin' : 'auto',
          }}
        >
          <table
            className={`
              table
              table-sm
            `}
            {...getTableProps()}
          >
            <thead>
              {headerGroups.map((row, i) => (
                <tr key={i} {...row.getHeaderGroupProps()}>
                  {row.headers.map((column, i) => (
                    <th
                      key={i}
                      style={{ width: column.width }}
                      {...column.getHeaderProps()}
                    >
                      {column.render('Header')}

                      {column.info && (
                        <>
                          &nbsp;
                          <IconButton
                            onClick={() => {}}
                            id={'table-column-header-tooltip-' + column.id}
                            tooltipOverride={column.info}
                            iconOverride={'fas fa-info-circle'}
                            type={'details'}
                            style={{ padding: 0 }}
                          />
                        </>
                      )}

                      {tableConfig.tableProps.resizable && (
                        <div
                          {...column.getResizerProps()}
                          className={
                            column.isResizing ? 'resizer isResizing' : 'resizer'
                          }
                        />
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>

            <tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row)

                return (
                  <React.Fragment key={i}>
                    <tr
                      className={`
                        ${row.isExpanded && 'expanding '}
                      `}
                      {...row.getRowProps()}
                      key={i}
                      style={{
                        backgroundColor: i % 2 === 0 ? '#eaeaea' : 'white',
                        textAlign: 'left',
                      }}
                    >
                      {row.cells.map((cell, i) => (
                        <td key={i} {...cell.getCellProps()}>
                          {cell.render('Cell')}
                        </td>
                      ))}
                    </tr>

                    {subComponent && row.isExpanded ? (
                      <tr
                        className={`
                            sub-component
                          `}
                        style={{
                          backgroundColor: i % 2 === 0 ? '#eaeaea' : 'white',
                          textAlign: 'left',
                        }}
                      >
                        <td
                          style={{
                            backgroundColor: i % 2 === 0 ? '#eaeaea' : 'white',
                            textAlign: 'left',
                          }}
                        >
                          <SubContainer className='sub-container'>
                            {subComponent({ row }, toggleAllRowsExpanded)}
                          </SubContainer>
                        </td>
                      </tr>
                    ) : null}
                  </React.Fragment>
                )
              })}

              {tableConfig.tableProps.hasPagination &&
                (data.length > tableConfig.tableProps.defaultPageSize ||
                  (actualPage && count && limit && data.length < limit)) &&
                handleOthersRows()}
            </tbody>

            {tableConfig.tableProps.hasFooter && page.length > 0 && (
              <tfoot>
                {footerGroups.map((group, i) => (
                  <tr key={i} {...group.getFooterGroupProps()}>
                    {group.headers.map((column, i) => (
                      <td key={i} {...column.getFooterProps()}>
                        {column.render('Footer')}
                      </td>
                    ))}
                  </tr>
                ))}
              </tfoot>
            )}
          </table>
        </div>
      </Container>

      {!thin &&
        (count > limit ||
          (tableConfig.tableProps.hasPagination &&
            data.length > tableConfig.tableProps.defaultPageSize)) && (
          <Pagination>
            <Col sm={3} md={4}>
              <TableChangePageButton
                type='button'
                onClick={handlePreviousPage}
                disabled={
                  (actualPage ? actualPage <= 1 : !canPreviousPage) ||
                  tableConfig.tableProps.loading ||
                  loading ||
                  data.length === 0
                }
              >
                {tableConfig.tableLabels.previousButtonText}
              </TableChangePageButton>
            </Col>

            <Col sm={6} md={4}>
              <Row>
                <Col sm={12} xl={6}>
                  <PageSelector>
                    <span>{tableConfig.tableLabels.pageText}</span>

                    <input
                      disabled={
                        actualPage && count && limit
                          ? false
                          : tableConfig.tableProps.loading ||
                            loading ||
                            data.length === 0
                      }
                      type='number'
                      min={1}
                      max={
                        actualPage && count && limit
                          ? Math.trunc(
                              count % limit !== 0
                                ? count / limit + 1
                                : count / limit,
                            ).toFixed(0)
                          : pageOptions.length
                      }
                      value={actualPage ?? pageIndex + 1}
                      onClick={e => {
                        return e.target.select()
                      }}
                      onChange={e => {
                        const maxPage = Math.trunc(
                          count % limit !== 0
                            ? count / limit + 1
                            : count / limit,
                        ).toFixed(0)

                        if (e.target.value <= maxPage && e.target.value > 0) {
                          handleToPage(e.target.value)

                          return setPageChanged(e.target.value)
                        }
                      }}
                    />

                    <span>{`${tableConfig.tableLabels.pageOfText} ${
                      count && limit
                        ? Math.trunc(
                            count % limit !== 0
                              ? count / limit + 1
                              : count / limit,
                          ).toFixed(0)
                        : pageOptions.length
                    }`}</span>
                  </PageSelector>
                </Col>

                <Col sm={12} xl={6}>
                  <PageSelector>
                    <select
                      disabled={
                        tableConfig.tableProps.loading ||
                        loading ||
                        data.length === 0
                      }
                      value={pageSize}
                      onChange={handlePageSize}
                    >
                      {[5, 10, 15, 25, 50].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                          {`${pageSize} ${tableConfig.tableLabels.pageSizeSelectText}`}
                        </option>
                      ))}
                    </select>
                  </PageSelector>
                </Col>
              </Row>
            </Col>

            <Col sm={3} md={4}>
              <TableChangePageButton
                type='button'
                onClick={handleNextPage}
                disabled={
                  (actualPage && count && limit
                    ? !(actualPage * limit < count)
                    : !canNextPage) ||
                  tableConfig.tableProps.loading ||
                  loading ||
                  data.length === 0
                }
              >
                {tableConfig.tableLabels.nextButtonText}
              </TableChangePageButton>
            </Col>
          </Pagination>
        )}

      {thin &&
        (count > limit ||
          (tableConfig.tableProps.hasPagination &&
            data.length > tableConfig.tableProps.defaultPageSize)) && (
          <PaginationThin>
            <TableChangePageButton
              type='button'
              onClick={handlePreviousPage}
              disabled={
                (actualPage ? actualPage <= 1 : !canPreviousPage) ||
                tableConfig.tableProps.loading ||
                loading ||
                data.length === 0
              }
            >
              <i className={'fas fa-arrow-left'} />
            </TableChangePageButton>

            <PageSelector>
              <span>{tableConfig.tableLabels.pageText}</span>

              <input
                disabled={
                  actualPage && count && limit
                    ? false
                    : tableConfig.tableProps.loading ||
                      loading ||
                      data.length === 0
                }
                type='number'
                min={1}
                max={
                  actualPage && count && limit
                    ? Math.trunc(
                        count % limit !== 0 ? count / limit + 1 : count / limit,
                      ).toFixed(0)
                    : pageOptions.length
                }
                value={actualPage ?? pageIndex + 1}
                onClick={e => {
                  return e.target.select()
                }}
                onChange={e => {
                  const maxPage = Math.trunc(
                    count % limit !== 0 ? count / limit + 1 : count / limit,
                  ).toFixed(0)

                  if (e.target.value <= maxPage && e.target.value > 0) {
                    handleToPage(e.target.value)

                    return setPageChanged(e.target.value)
                  }
                }}
              />

              <span>{`${tableConfig.tableLabels.pageOfText} ${
                count && limit
                  ? Math.trunc(
                      count % limit !== 0 ? count / limit + 1 : count / limit,
                    ).toFixed(0)
                  : pageOptions.length
              }`}</span>
            </PageSelector>

            <PageSelector>
              <select
                disabled={
                  tableConfig.tableProps.loading || loading || data.length === 0
                }
                value={pageSize}
                onChange={handlePageSize}
              >
                {[5, 10, 15, 25, 50].map(pageSize => (
                  <option key={pageSize} value={pageSize}>
                    {`${pageSize} ${tableConfig.tableLabels.pageSizeSelectText}`}
                  </option>
                ))}
              </select>
            </PageSelector>

            <TableChangePageButton
              type='button'
              onClick={handleNextPage}
              disabled={
                (actualPage && count && limit
                  ? !(actualPage * limit < count)
                  : !canNextPage) ||
                tableConfig.tableProps.loading ||
                loading ||
                data.length === 0
              }
            >
              <i className={'fas fa-arrow-right'} />
            </TableChangePageButton>
          </PaginationThin>
        )}
    </>
  )
}

const TableChangePageButton = styled.button`
  opacity: ${props => (props.disabled ? '0.5' : '1')};
`

Table.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  subComponent: PropTypes.func,
  tableLabels: PropTypes.shape({
    loadingText: PropTypes.string,
    noDataText: PropTypes.string,
    nextButtonText: PropTypes.string,
    previousButtonText: PropTypes.string,
    pageText: PropTypes.string,
    pageOfText: PropTypes.string,
    pageSizeSelectText: PropTypes.string,
  }),
  tableStyle: PropTypes.shape({
    fontSize: PropTypes.string,
    cellPadding: PropTypes.string,
  }),
  tableProps: PropTypes.shape({
    resizable: PropTypes.bool,
    hasPagination: PropTypes.bool,
    hasFooter: PropTypes.bool,
    defaultPageSize: PropTypes.number,
    loading: PropTypes.bool,
  }),
  loading: PropTypes.bool,
}

export default Table
