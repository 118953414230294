import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { getDashboardPlaceholders } from '~/components/Analytics/Dashboard'
import { apiRequest } from '~/apiService'

export default function useHoldingBackofficeCustomersData({
  customersFilter,
  handleProfile,
}) {
  const [loading, setLoading] = useState(false)
  const dashboardsPlaceholders = getDashboardPlaceholders(6)
  const [dashboards, setDashboards] = useState([])
  const [dashboardsFluxoDeAtendimento, setDashboardsFluxoDeAtendimento] =
    useState([])
  const [tableData, setTableData] = useState([])
  const [canceledProspectionsChart, setCanceledProspectionsChart] =
    useState(undefined)
  const [leadContactFormChart, setLeadContactFormChart] = useState(undefined)
  // const [lossRateDetails, setLossRateDetails] = useState(undefined)

  const params = {
    branchs: customersFilter?.branchs
      ? customersFilter.branchs.map(x => x.value)
      : [],
    start: customersFilter?.start ?? undefined,
    end: customersFilter?.end ?? undefined,
    requestFromAgency: handleProfile,
    agentId: customersFilter?.consultant?.value,
  }

  useEffect(() => {
    getData()
  }, [customersFilter])

  async function getData() {
    try {
      setLoading(true)

      const [
        dashboards,
        dashboardsFluxoDeAtendimento,
        canceledProspectionsChart,
        tableData,
        leadContactFormChart,
        // lossRateDetails,
      ] = await Promise.allSettled([
        apiRequest('get', 'holdingbackoffice/customers/dashboards', params),
        apiRequest(
          'get',
          'holdingbackoffice/customers-fluxo-de-atendimento/dashboards',
          params,
        ),
        apiRequest(
          'get',
          'holdingbackoffice/customers/chart/canceled-prospections',
          params,
        ),
        apiRequest('get', 'holdingbackoffice/customers/table', params),
        apiRequest(
          'get',
          'holdingbackoffice/customers/chart/lead-contact-form',
          params,
        ),
        // apiRequest(
        //   'get',
        //   'holdingbackoffice/customers-fluxo-de-atendimento/details-loss-rate',
        //   params,
        // ),
      ])

      setDashboards(dashboards?.value?.data)
      setDashboardsFluxoDeAtendimento(dashboardsFluxoDeAtendimento?.value?.data)
      setCanceledProspectionsChart(canceledProspectionsChart?.value?.data)
      setTableData(tableData?.value?.data)
      setLeadContactFormChart(leadContactFormChart?.value?.data)
      // setLossRateDetails(lossRateDetails?.value?.data)
    } catch (error) {
      toast.info('Dados não encontrados. Tente mais tarde!')
      setLoading(false)
      setDashboards(dashboardsPlaceholders)
      setTableData([])
      setCanceledProspectionsChart(undefined)
      setLeadContactFormChart(undefined)
      // setLossRateDetails(undefined)
    } finally {
      setLoading(false)
    }
  }

  return {
    loading,
    dashboards,
    dashboardsFluxoDeAtendimento,
    tableData,
    canceledProspectionsChart,
    leadContactFormChart,
    // lossRateDetails,
  }
}
