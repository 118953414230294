export const getParticipantInputs = agency => {
  return [
    {
      type: 'text',
      name: 'index',
      label: 'Posição de incrição',
      disabled: true,
      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'grade',
      label: 'Série',

      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'class',
      label: 'Turma',

      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'fullName',
      label: 'Nome completo',

      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'email',
      label: 'E-mail',

      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'phone',
      label: 'Telefone',

      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'cpf',
      label: 'Cpf',

      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'rg',
      label: 'Rg',

      sm: 12,
      lg: 4,
    },
    {
      type: 'date',
      name: 'birth',
      label: 'Data de nascimento',

      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'fatherName',
      label: 'Nome do pai',

      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'fatherPhone',
      label: 'Telefone do pai',

      sm: 12,
      lg: 4,
    },
    // {
    //   type: 'text',
    //   name: 'fatherEmail',
    //   label: 'E-mail do pai',
    //
    //   sm: 12,
    //   lg: 4,
    // },
    {
      type: 'text',
      name: 'motherName',
      label: 'Nome da mãe',

      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'motherPhone',
      label: 'Telefone da mãe',

      sm: 12,
      lg: 4,
    },
    // {
    //   type: 'text',
    //   name: 'motherEmail',
    //   label: 'E-mail da mãe',
    //
    //   sm: 12,
    //   lg: 4,
    // },
    {
      type: 'text',
      name: 'emergencyName',
      label: 'Nome do contato de emergência',

      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'emergencyPhone',
      label: 'Telefone do contato de emergência',

      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'emergencyEmail',
      label: 'E-mail do contato de emergência',

      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'tshirtNumber',
      label: 'Número da camisa',

      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'responsibleKinship',
      label: 'Parentesco com responsável financeiro',

      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'weight',
      label: 'Peso (em quilos)',

      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'shoeNumber',
      label: 'Número do Pé',

      sm: 12,
      lg: 4,
    },
    agency === 'Wee Travel' && {
      type: 'text',
      name: 'planaSub',
      label:
        'O(a) aluno(a) irá participar da experiência do PlanaSub? Valor adicional R$ 375,00 por pessoa.',

      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'medicalAllergy',
      label: 'Alergias',

      sm: 12,
    },
    {
      type: 'text',
      name: 'medicalMedication',
      label: 'Medicamentos de uso contínuo',

      sm: 12,
    },
    {
      type: 'text',
      name: 'medicalDiseasePhobia',
      label: 'Doença crônica/fobia ou necessidade especial',

      sm: 12,
    },
    {
      type: 'text',
      name: 'medicalFoodRestriction',
      label: 'Restrição alimentar ou algo que não aprecie',

      sm: 12,
    },
  ]
}

export const getParticipantInputsMinimal = () => {
  return [
    {
      type: 'text',
      name: 'index',
      label: 'Posição de incrição',
      disabled: true,
      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'fullName',
      label: 'Nome completo',
      // disabled: true,
      sm: 12,
      lg: 4,
    },
    {
      type: 'email',
      name: 'email',
      label: 'E-mail',
      // disabled: true,
      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'phone',
      label: 'Telefone',
      mask: '(99) 99999-9999',
      // disabled: true,
      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'address',
      label: 'Endereço',
      // disabled: true,
      sm: 12,
      lg: 4,
    },
  ]
}

export const getResponsibleInputs = () => {
  return [
    {
      type: 'text',
      name: 'fullName',
      label: 'Nome completo',
      disabled: true,
      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'email',
      label: 'E-mail',
      disabled: true,
      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'phone',
      label: 'Telefone',
      disabled: true,
      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'profession',
      label: 'Profissão',
      disabled: true,
      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'businessPhone',
      label: 'Telefone comercial',
      disabled: true,
      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'rg',
      label: 'Rg',
      disabled: true,
      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'cpf',
      label: 'Cpf',
      disabled: true,
      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'birth',
      label: 'Data de nascimento',
      disabled: true,
      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'address',
      label: 'Endereço',
      disabled: true,
      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'paymentMethod',
      label: 'Forma de pagamento',
      disabled: true,
      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'installments',
      label: 'Quantidade de parcelas',
      disabled: true,
      sm: 12,
      lg: 4,
    },
  ]
}

export const getEventInputs = () => {
  return [
    {
      type: 'text',
      name: 'title',
      label: 'Título',
      disabled: true,
      sm: 12,
      lg: 6,
    },
    {
      type: 'text',
      name: 'destiny',
      label: 'Destino',
      disabled: true,
      sm: 12,
      lg: 6,
    },
    {
      type: 'text',
      name: 'end',
      label: 'Fim',
      disabled: true,
      sm: 12,
      lg: 6,
    },
    {
      type: 'text',
      name: 'start',
      label: 'Início',
      disabled: true,
      sm: 12,
      lg: 6,
    },
  ]
}

export const getSchoolInputs = () => {
  return [
    {
      type: 'text',
      name: 'name',
      label: 'Nome do Grupo',
      disabled: true,
      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'phone',
      label: 'Telefone',
      disabled: true,
      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'email',
      label: 'E-mail',
      disabled: true,
      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'site',
      label: 'Site',
      disabled: true,
      sm: 12,
      lg: 4,
    },
    {
      type: 'text',
      name: 'address',
      label: 'Endereço',
      disabled: true,
      sm: 12,
      lg: 4,
    },
    // {
    //   type: 'text',
    //   name: 'hood',
    //   label: 'Bairro',
    //   disabled: true,
    //   sm: 12,
    //   lg: 4,
    // },
    // {
    //   type: 'text',
    //   name: 'city',
    //   label: 'Cidade',
    //   disabled: true,
    //   sm: 12,
    //   lg: 4,
    // },
    // {
    //   type: 'text',
    //   name: 'state',
    //   label: 'Estado',
    //   disabled: true,
    //   sm: 12,
    //   lg: 4,
    // },
  ]
}
