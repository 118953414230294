import PerfectScrollbar from 'react-perfect-scrollbar'
import PageSettings from '~/config/PageSettings'
import SidebarNav from './Nav'
import { Link } from 'react-router-dom'
import { ButtonMenu } from '../Header/styles'
import { phoneNumber } from '~/utils/supportPhone'

export default function Sidebar() {
  return (
    <PageSettings.Consumer>
      {pageSettings => (
        <>
          <div
            id='sidebar'
            className={
              `sidebar` +
              (pageSettings.pageSidebarTransparent
                ? ' sidebar-transparent'
                : '')
            }
          >
            <PerfectScrollbar
              className='height-full'
              options={{ suppressScrollX: true }}
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <div>
                <br />
                <SidebarNav pageSettings={pageSettings} />
              </div>

              {!pageSettings.pageSidebarMinify && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    fontSize: '14px',
                    padding: '8px',
                  }}
                >
                  <ButtonMenu
                    style={{ color: '#333' }}
                    target='_blank'
                    rel='noopener noreferrer'
                    type='button'
                    onClick={() =>
                      window.open(
                        `https://api.whatsapp.com/send?phone=${phoneNumber(
                          'support',
                        )}`,
                      )
                    }
                  >
                    <b>Precisa de ajuda?</b>
                    <p>Entre em contato com o nosso suporte clicando aqui</p>
                  </ButtonMenu>

                  <div>
                    <Link
                      to='#'
                      className='sidebar-minify-btn'
                      onClick={pageSettings.toggleSidebarMinify}
                    >
                      <i className='fa fa-angle-double-left' />
                    </Link>
                  </div>
                </div>
              )}

              {pageSettings.pageSidebarMinify && (
                <div>
                  <Link
                    to='#'
                    className='sidebar-minify-btn'
                    onClick={pageSettings.toggleSidebarMinify}
                  >
                    <i className='fa fa-angle-double-left' />
                  </Link>
                </div>
              )}
            </PerfectScrollbar>
          </div>

          <div className='sidebar-bg' />
        </>
      )}
    </PageSettings.Consumer>
  )
}
