import { useParams } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { GetAxios } from '~/services/api'
import moment from 'moment'
import Preview from './Components/Preview'
import LoadingContainer from '~/components/LoadingContainer'
import Download from './Components/Download'
import { getDateFormated } from '~/functions/Date'
import Buttons from './Components/Buttons'
import styled from 'styled-components'
import useTheme from '~/hooks/useTheme'

export default function ProposalPdf() {
  const params = useParams()
  const { axiosApis } = GetAxios()
  const [proposalDetails, setProposalDetails] = useState([])
  const [loading, setLoading] = useState(false)
  const [fullWhite, setFullWhite] = useState(false)
  // const [hideCover, setHideCover] = useState(false)
  const [withoutPadding, setWithoutPadding] = useState(false)
  const [isMobile] = useState(() => {
    if (
      navigator.userAgent.match(/Android/i) != null ||
      navigator.userAgent.match(/iPhone/i) != null
    ) {
      return true
    } else {
      return false
    }
  })
  const [showPrint, setShowPrint] = useState(false)
  const [showButtons, setShowButtons] = useState(false)
  const { updateFont } = useTheme()

  useEffect(() => {
    getTravelDestinyFromRoute()
  }, [])

  // useEffect(() => {
  //   function handleKeyDown(event) {
  //     if (event.ctrlKey && event.key === 'p') {
  //       event.preventDefault()

  //       setShowPrint(true)
  //       setShowButtons(true)
  //       setWithoutPadding(true)
  //       setHideCover(true)

  //       setTimeout(() => {
  //         window.print()
  //         setShowButtons(false)
  //         setHideCover(false)
  //         setWithoutPadding(false)

  //         console.log('entrou no false')
  //       }, 500)
  //     }
  //   }

  //   document.addEventListener('keydown', handleKeyDown)

  //   return () => {
  //     document.removeEventListener('keydown', handleKeyDown)
  //   }
  // }, [])

  async function getTravelDestinyFromRoute() {
    try {
      setLoading(true)

      const { data } = await axiosApis.get(
        `${process.env.REACT_APP_ENDPOINT_APIS}/proposal/pdf/${params.token}`,
      )

      const proposalDateFormated = {
        ...data,
        dateInitial: moment(data.dateInitial).format('LL'),
        dateFinal: moment(data.dateFinal).format('LL'),
        createdAt: getDateFormated(data.createdAt, 'DD/MM/YYYY'),
      }

      setProposalDetails(proposalDateFormated)

      if (data.fontFamily && data.fontFamilyUrl) {
        await updateFont(data.fontFamily, data.fontFamilyUrl)
      }

      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  const handlePrint = () => setShowPrint(!showPrint)

  const handleDownload = () => {
    setShowButtons(true)
    setFullWhite(true)
    setWithoutPadding(true)

    setTimeout(() => {
      window.print()
      setShowButtons(false)
      setFullWhite(false)
      setWithoutPadding(false)
    }, 500)
  }

  const proposalSectionsList = proposal => {
    let proposalsList = []

    // switch (proposal) {
    //   case proposal['proposalHotels'] :
    //     proposalsList.push(proposal['proposalHotels'])
    //     break
    //   default:

    // }

    if (proposal?.proposalDestinys?.length >= 1) {
      proposalsList.push({
        name: 'proposalDestinys',
        proposalDestinys: proposal.proposalDestinys,
      })
    }
    if (proposal?.proposalFlights?.length >= 1) {
      proposalsList.push({
        name: 'proposalFlights',
        proposalFlights: proposal.proposalFlights,
      })
    }
    if (proposal?.proposalNauticals?.length >= 1) {
      proposalsList.push({
        name: 'proposalNauticals',
        proposalNauticals: proposal.proposalNauticals,
      })
    }
    if (proposal?.proposalTrains?.length >= 1) {
      proposalsList.push({
        name: 'proposalTrains',
        proposalTrains: proposal.proposalTrains,
      })
    }
    if (proposal?.proposalTransfers?.length >= 1) {
      proposalsList.push({
        name: 'proposalTransfers',
        proposalTransfers: proposal.proposalTransfers,
      })
    }
    if (proposal?.proposalHotels?.length >= 1) {
      proposalsList.push({
        name: 'proposalHotels',
        proposalHotels: proposal.proposalHotels,
      })
    }
    if (proposal?.proposalActivitys?.length >= 1) {
      proposalsList.push({
        name: 'proposalActivitys',
        proposalActivitys: proposal.proposalActivitys,
      })
    }
    if (proposal?.proposalInsurances?.length >= 1) {
      proposalsList.push({
        name: 'proposalInsurances',
        proposalInsurances: proposal.proposalInsurances,
      })
    }
    if (proposal?.proposalOtherServices?.length >= 1) {
      proposalsList.push({
        name: 'proposalOtherServices',
        proposalOtherServices: proposal.proposalOtherServices,
      })
    }
    if (proposal?.proposalTickets?.length >= 1) {
      proposalsList.push({
        name: 'proposalTickets',
        proposalTickets: proposal.proposalTickets,
      })
    }
    if (proposal?.proposalVehicleRentals?.length >= 1) {
      proposalsList.push({
        name: 'proposalVehicleRentals',
        proposalVehicleRentals: proposal.proposalVehicleRentals,
      })
    }
    if (proposal?.alter) {
      proposalsList.push({
        name: 'alter',
        alter: proposal.alter,
      })
    }
    if (proposal?.cancel) {
      proposalsList.push({
        name: 'cancel',
        cancel: proposal.cancel,
      })
    }
    if (proposal?.countryExi) {
      proposalsList.push({
        name: 'countryExi',
        countryExi: proposal.countryExi,
      })
    }
    if (proposal?.included) {
      proposalsList.push({
        name: 'included',
        included: proposal.included,
      })
    }
    if (proposal?.notIncluded) {
      proposalsList.push({
        name: 'notIncluded',
        notIncluded: proposal.notIncluded,
      })
    }
    if (proposal?.reservation) {
      proposalsList.push({
        name: 'reservation',
        reservation: proposal.reservation,
      })
    }
    return proposalsList
  }

  return (
    <Container>
      {loading && <LoadingContainer height='100vh' />}

      {!loading && (
        <div>
          {!showPrint && (
            <Preview
              proposalList={
                proposalDetails ? proposalSectionsList(proposalDetails) : []
              }
              id={proposalDetails?.id}
              createdAt={proposalDetails?.createdAt}
              agency={proposalDetails?.agencyImage}
              primaryColor={proposalDetails?.primaryColor}
              secondaryColor={proposalDetails?.secondaryColor}
              title={proposalDetails.title}
              dateInitial={proposalDetails.dateInitial}
              dateFinal={proposalDetails.dateFinal}
              image={proposalDetails.image}
              image2={proposalDetails.image2}
              description={proposalDetails.description}
              observation={proposalDetails.observation}
              included={proposalDetails.included}
              notIncluded={proposalDetails.notIncluded}
              alter={proposalDetails.alter}
              cancel={proposalDetails.cancel}
              reservation={proposalDetails.reservation}
              countryExi={proposalDetails.countryExi}
              allValues={proposalDetails.allValues}
              showTotalValue={proposalDetails.showTotalValue}
              dolar={proposalDetails.dolar}
              fullValue={proposalDetails.fullValue}
              proposalFlights={proposalDetails.proposalFlights}
              proposalNauticals={proposalDetails.proposalNauticals}
              proposalTrains={proposalDetails.proposalTrains}
              proposalTransfers={proposalDetails.proposalTransfers}
              proposalHotels={proposalDetails.proposalHotels}
              proposalActivitys={proposalDetails.proposalActivitys}
              proposalInsurances={proposalDetails.proposalInsurances}
              proposalDestinys={proposalDetails.proposalDestinys}
              proposalTickets={proposalDetails.proposalTickets}
              proposalVehicleRentals={proposalDetails.proposalVehicleRentals}
              proposalOtherServices={proposalDetails.proposalOtherServices}
              totalValue={proposalDetails.totalValue}
              agentName={proposalDetails.agentName}
              agentPhone={proposalDetails.agentPhone}
              agentEmail={proposalDetails.agentEmail}
              agencyPhone={proposalDetails.agencyPhone}
              isMobile={isMobile}
              handlePrint={handlePrint}
              handleDownload={handleDownload}
            />
          )}
          {showPrint && (
            <div>
              {!showButtons && (
                <Buttons
                  primaryColor={proposalDetails?.primaryColor}
                  handlePrint={handlePrint}
                  handleDownload={handleDownload}
                />
              )}
              <Download
                id={proposalDetails?.id}
                fullWhite={fullWhite}
                withoutPadding={withoutPadding}
                createdAt={proposalDetails?.createdAt}
                agency={proposalDetails?.agencyImage}
                primaryColor={proposalDetails?.primaryColor}
                secondaryColor={proposalDetails?.secondaryColor}
                title={proposalDetails.title}
                dateInitial={proposalDetails.dateInitial}
                dateFinal={proposalDetails.dateFinal}
                image={proposalDetails.image}
                image2={proposalDetails.image2}
                description={proposalDetails.description}
                observation={proposalDetails.observation}
                included={proposalDetails.included}
                notIncluded={proposalDetails.notIncluded}
                alter={proposalDetails.alter}
                cancel={proposalDetails.cancel}
                reservation={proposalDetails.reservation}
                countryExi={proposalDetails.countryExi}
                allValues={proposalDetails.allValues}
                showTotalValue={proposalDetails.showTotalValue}
                dolar={proposalDetails.dolar}
                fullValue={proposalDetails.fullValue}
                proposalFlights={proposalDetails.proposalFlights}
                proposalNauticals={proposalDetails.proposalNauticals}
                proposalTrains={proposalDetails.proposalTrains}
                proposalTransfers={proposalDetails.proposalTransfers}
                proposalHotels={proposalDetails.proposalHotels}
                proposalActivitys={proposalDetails.proposalActivitys}
                proposalInsurances={proposalDetails.proposalInsurances}
                proposalDestinys={proposalDetails.proposalDestinys}
                proposalOtherServices={proposalDetails.proposalOtherServices}
                proposalTickets={proposalDetails.proposalTickets}
                proposalVehicleRentals={proposalDetails.proposalVehicleRentals}
                totalValue={proposalDetails.totalValue}
                agentName={proposalDetails.agentName}
                agentPhone={proposalDetails.agentPhone}
                agentEmail={proposalDetails.agentEmail}
                agencyPhone={proposalDetails.agencyPhone}
                // hideCover={hideCover}
              />
            </div>
          )}
        </div>
      )}
    </Container>
  )
}

const Container = styled.div`
  background: #ffffff;

  * {
    font-family: ${props => props.theme.fontFamily};
  }
`
