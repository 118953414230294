import styled from 'styled-components'
import AnalyticsTable from '~/components/Analytics/Table'
import HomeDashboardTitle from '../Title'

export default function HomeDashboardTableFlights({ embarques, loading }) {
  return (
    <Container>
      <HomeDashboardTitle title={'Lista de embarques'} />

      <AnalyticsTable
        data={embarques}
        loading={loading}
        thin={true}
        columns={[
          {
            Header: 'Embarque',
            accessor: 'data_embarque',
            width: 80,
          },
          {
            Header: 'Viajante',
            accessor: 'name_viajante',
            width: 290,
          },
          {
            Header: 'Consultor',
            accessor: 'name_agent',
            width: 80,
            Cell: props => {
              if (!props.row.original.name_agent) {
                return ''
              }

              return props.row.original.name_agent.split(' ')[0]
            },
          },
          {
            Header: 'Origem',
            accessor: 'city_origin',
            width: 60,
          },
          {
            Header: 'Destino',
            accessor: 'city_destiny',
            width: 60,
          },
        ]}
      />
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(50% - 8px);
  min-width: 540px;
  max-width: 100%;
  padding: 4px;

  .table-responsive {
    max-height: 200px;
    overflow: auto;
  }

  @media (max-width: 920px) {
    height: auto;
  }
`
